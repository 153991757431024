// Spain

export const locale = {
	lang: 'es',
	data: {
		AGENT: 'Agente',
		SUPERVISOR: 'Supervisor',
		USER: 'Usuario',
		ROLE: 'Rol',
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		INTERNET: {
			ERRORMESSAGE: 'No hay conexión a Internet, se está reintentando conectar con el socket del servidor. Toda la nueva información será cargada una vez que se restaure la conexión.',
			RESUMEMESSAGE: 'Reconexión con el socket del servidor exitosa. La nueva información ha sido recargada, las notificaciones se han activado nuevamente. ',
			ERRORMESSAGEONSEND: 'No hay conexión a Internet, por favor intente nuevamente'

		},

		LOGIN: {
			TITLE:
			{
				INGRESAR: 'Login',
			},
			INPUT:
			{
				USUARIO: 'Ingrese su email',
				CONTRASENA: 'Ingrese su contraseña',
			},
			VALIDATION:
			{
				USUARIO_REQUERIDO: 'Debe ingresar su email',
				CONTRASENA_REQUERIDO: 'Debe ingresar su contraseña',
				LOGIN_INVALIDO: "Email o contraseña incorrectos, o usuario inactivo",
				LOGIN_ERROR: "Error desconocido, por favor comuníquese con soporte"
			},
			LINK:
			{
				OLVIDO_CONTRASENA: 'Olvidé la contraseña'
			},
			BUTTON:
			{
				INGRESAR: 'INGRESAR'
			},
			TEXT:
			{
				CONTACTANOS: "CONTÁCTANOS"
			},
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Indicadores'
		},
		MENU: {
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero',
			INDICATORS: 'Indicadores',
			CONTACTS: 'Contactos',
			CHAT: 'Chat',
			QUEUES: 'Bandejas',
			USERS: 'Usuarios',
			PROFILE: 'Perfil',
			CONFIGURATION: 'Configuración',


		},
		CONTACTS:
		{

			CONTACT:
			{
				SEARCHTEMPLATE: 'Buscar template',
				PREVIEWTITLE: 'Preview',
				FILEURL: 'Url externa de archivo',
				FILEUPLOAD: 'O cargar archivo',
				SENDTEMPLATE: 'Enviar Template',
				BODYPARAMETERS: 'Parámetros del cuerpo',
				TEMPLATESELECTION: 'Selección del template',
				PARAMETERS: 'Ingreso de parámetros',
				TEMPLATES: 'Templates',
				EVENTMESSAGES:
				{
					SENDTEMPLATEOK: "El template fue programado para su envío correctamente y será enviado en unos segundos.",
					SENDTEMPLATEERROR: "Ocurrió un error al programar el template",
					CREATEOK: "El contacto fue creado correctamente",
					SAVEOK: "El contacto fue guardado correctamente",
					ERRORDATERANGE: "Debe seleccionar un rango de fechas",
					ERRORMETA: "Debe ingresar un operador y un valor para la bÚsqueda por Metadata",
					RESETFILTERSOK: "Filtros reseteados correctamente"
				},
				SAVE: "Guardar",
				NEWCONTACT: "Nuevo contacto",
				RESETFILTERTOOLTIP: "Resetear todos los filtros y el ordenamiento",
				RESETFILTER: "Resetear filtros",
				EDITCONTACT: "Editar contacto",
				ALLMETADATA: "Meta-data",
				ALLCHAT: "Chat",
				TITLE:
				{
					CUSTOMPROFILEDATA: "Otros datos de perfil"
				},
				BACK: "Volver",
				CHANNEL: "Canal",
				ID: "Identificador",
				ENTEREL: "Ingrese el",
				NAME: "Nombre",
				EMAIL: "Email",
				COUNTRY: "País",
				STATE: "Estado/Provincia",
				ADDRESS: "Dirección",
				ZIPCODE: "Código postal",
				LINKTO: "Link externo",
				DESCRIPTION: "Descripción",
				STATUS: "Estado",
				LASTMESSAGE: "Último mensaje",
				LASTMESSAGETIME: "Fecha",
				ERRORTRYAGAIN: "Error intenta denuevo",
				BASICINFO: "Información básica",
				CONTACTDATA: "Datos de perfil",
				TOOLTIP: {
					BACKTOCONTACTLIST: "Volver a la lista de contactos",
					GOTOLINK: "Ir al link",
					SAVE: "Guardar Información básica",
					CREATENEWCONTACT: "Crear nuevo contacto"
				}
			},
			CONFIRMDIALOG:
			{
				CONFIRMSENDRSUMEHSMP1: '¿Esta seguro que desea enviar un HSM para retomar la conversación, recuerde que tiene un costo asociado?',
				CONFIRMSENDRSUMEHSMP2: 'De continuar, el HSM puede demorar hasta 20 segundos en ser recibido por el cliente y  el mismo ingresará a la bandeja General una vez que haya respondido',
				NO: 'NO',
				YES: 'SI'

			},

			EVENTMESSAGES:
			{
				SENDTOBOTOK: 'El contacto fue enviado al bot correctamente',
				SENDTOBOTERROR: 'No fue posible enviar el contacto al bot',
				SENDTOAGENTOK: 'El contacto fue enviado al agente correctamente.',
				SENDTOAGENTERROR: 'No fue posible enviar el contacto al agente',
				SENDTOQUEUEERROR: 'No es posible realizar la acción, el contacto ha sido movido o tomado por otro agente',
				SENDTOQUEUEOK: 'El contacto ha sido movido de bandeja correctamente',
				SENDHSMRESUMEOK: 'El mensaje para retomar la conversación será enviado en unos segundos',
				SENDHSMRESUMEERROR: 'No fue posible enviar el mensaje para retomar la conversación',
				SENDTHSMRESUMEERRORNOTEMPLATE: 'No hay un template para retomar conversación asignado al bot'

			},
			PAGE:
			{
				TITLE:
				{
					CONTACTSLIST: 'Lista de contactos',
					CONTACTSLISTFILTER: 'Lista de contacto - FILTRAR POR',
					FILTERBY: 'Filtrar por'
				}
			},
			TABLE:
			{
				FILTER:
				{

					TEXT: {
						LASTMESSAGETIME: 'Fecha:',
						SEARCHCONTACTBY: 'Buscar contacto por:',
						STATUS: 'Estado',
						INTERACTIONWHENTH: 'La interacción paso por',
						FINISHAT: 'Finalizó en',
						METADATA: 'Metadata',
						SEARCH: 'Buscar',
						RESETFILTERS: 'Resetear filtros',
						VALUE: 'Valor',
						KEY: "Clave",
						EQUAL: "Igual",
						NOTEQUAL: "Distinto",
						CONTAINS: "Contiene",
						NOTCONTAINS: "No Contiene",
						GREATER: "Mayor",
						LESS: "Menor",
						LESSEQUAL: "Menor igual",
						GREATEREQUAL: "Mayor Igual",

					},
					FIELD:
					{
						FROM: 'Desde',
						TO: 'Hasta',
						ATLEAST3: '(al menos 3 caracteres)',
						ID: 'Identificador',
						CURRENT: 'Datos actuales',
						HISTORICAL: 'Datos históricos',
					}
				},
				LABELS:
				{
					LINKTODOCUMENT: 'Archivo o Multimedia',
					LINKTOGOOGLE: 'Ubicación',
					CONTACTSBYPAGE: 'Contactos por página'
				},
				NORECORDS: 'No hay registros',
				PLEASEWAIT: 'Por favor espere',
				HEADER:
				{
					FINALCLIENTID: 'Identificador',
					CHANNEL: 'Canal',
					DISPLAYNAME: 'Nombre',
					LASTMESSAGE: 'Último mensaje',
					LASTMESSAGETIME: 'Fecha ult. mensaje',
					LASTUPDATETIME: 'Fecha estado',
					STATUS: 'Estado',
					PRESTATUS: 'Estado previo',
					ACTIONS: 'Acciones',
					LASTINTQUEUE: 'Última bandeja intermedia'

				},
				STATUS:
				{
					BOT: 'BOT (NO EXPIRADO)',
					AGENT: 'AGENTE',
					EXPIRED: 'EXPIRADO',
					QUEUE: 'BANDEJA',
					LRM: 'Ú.M.R'


				},
				ACTIONS:
				{
					TOOLTIP:
					{
						SHOWCONTACT: 'Ver contacto',
						SENDRESUMEHSM: 'Enviar mensaje para retomar conversación',
						SENDTOAGENT: 'Enviar contacto a modo agente',
						SENDTOBOT: 'Enviar contacto a modo bot',
						SENDTOQUEUE: 'Enviar contacto a bandeja'
					},
					SENDTOQUEUE:
					{
						SELECT:
						{
							SEARCH: 'Buscar (al menos 3 caracteres)',
							NOMATCH: 'no hay coincidencias'
						}
					}
				}
			}
		}
		,
		INDICATORS:
		{
			PAGE:
			{
				LABELS:
				{
					CHATCONVERSATIONNAME: 'Chat conversations',
					CHATCONVERSATIONDESC: 'Cantidad total de contactos que entraron a cada bandeja dentro del período de tiempo',
					CURRENTCHATNAME: 'Current Chats',
					CURRENTCHATDESC: 'Cantidad de chats actualmente abiertos',
					COMPLETECHATNAME: 'Complete Chats',
					COMPLETECHATDESC: 'Cantidad de chats abiertos por algún agente y cerrados dentro del período de tiempo',
					WAITTIMECONVERSARIONNAME: 'Conversation Wait Time',
					WAITTIMECONVERSARIONDESC: 'Porcentaje de atenciones dentro de slots de tiempo'
				}
			},
			CHATCONVERSATION:
			{
				SCROLLBARX: "Seleccione la franja de tiempo a visualizar en la barra, o seleccione horizontalmente en la gráfica para hacer zoom",
				NOINFO: "No existe información para el período",
				CSV:
				{
					HEADERS:
					{
						QUEUEID: "bandeja_id",
						QUEUENAME: "bandeja_nombre",
						COUNT: "cantidad",
						DATE: "fecha"
					}
				},
				LABELS:
				{
					FROM: 'Desde:',
					TO: 'Hasta:',
					FILTERQUEUES: 'Filtrar bandejas',
					ALLQUEUES: 'Todas las bandejas'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos del rango seleccionado',
						TODAYDATA: 'Datos de hoy',
						ONEWEEK: 'Desde 1 semana atrás',
						ONEMONTH: 'Desde 1 mes atrás',
						THREEMONTH: 'Desde 3 meses atrás',
						SIXMONTH: 'Desde 6 meses atrás',
						ONEYEAR: 'Desde 1 año atrás',
						BEGINYEAR: 'Desde principios del año',
						FULLDATA: 'Período máximo en el que hay datos'
					},
					TEXT:
					{
						TODAY: 'Hoy',
						ONEWEEK: '1s',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1a',
						BEGINYEAR: 'pa',
						FULLDATA: 'MÁX'
					},

					TOOLTIP: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHQUEUE: 'Buscar'
					},
					NOMATCHS: 'no hay coincidencias'
				}
			},
			WAITTIMECONVERSATION:
			{
				SCROLLBARX: "Seleccione la franja de tiempo a visualizar en la barra, o seleccione horizontalmente en la gráfica para hacer zoom",
				NOINFO: "No existe información para el período",
				CSV:
				{
					HEADERS:
					{
						QUEUEID: "bandeja_id",
						QUEUENAME: "bandeja_nombre",
						COUNT: "cantidad",
						DATE: "fecha",
						PERCENT: "porcentaje",
						SLOT: "slot_tiempo"

					}
				},
				LABELS:
				{
					FROM: 'Desde:',
					TO: 'Hasta:',
					FILTERQUEUES: 'Filtrar bandejas',
					ALLQUEUES: 'Todas las bandejas'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos del rango seleccionado',
						TODAYDATA: 'Datos de hoy',
						ONEWEEK: 'Desde 1 semana atrás',
						ONEMONTH: 'Desde 1 mes atrás',
						THREEMONTH: 'Desde 3 meses atrás',
						SIXMONTH: 'Desde 6 meses atrás',
						ONEYEAR: 'Desde 1 año atrás',
						BEGINYEAR: 'Desde principios del año',
						FULLDATA: 'Período máximo en el que hay datos'
					},
					TEXT:
					{
						TODAY: 'Hoy',
						ONEWEEK: '1s',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1a',
						BEGINYEAR: 'pa',
						FULLDATA: 'MÁX'
					},

					TOOLTIP: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHQUEUE: 'Buscar'
					},
					NOMATCHS: 'no hay coincidencias'
				}
			},
			CURRENTCHAT:
			{
				SCROLLBARX: "Seleccione la franja de tiempo a visualizar en la barra, o seleccione horizontalmente en la gráfica para hacer zoom",
				NOINFO: "No existe información para el período",
				CSV:
				{
					HEADERS:
					{
						AGENTID: "agente_id",
						AGENTEMAIL: "agente_email",
						COUNT: "cantidad",
						DATE: "fecha"
					}
				},
				LABELS:
				{
					FROM: 'Desde:',
					TO: 'Hasta:',
					FILTERAGENTS: 'Filtrar agentes',
					ALLAGENTS: 'Todos los agentes'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos del rango seleccionado',
						TODAYDATA: 'Datos de hoy',
						ONEWEEK: 'Desde 1 semana atrás',
						ONEMONTH: 'Desde 1 mes atrás',
						THREEMONTH: 'Desde 3 meses atrás',
						SIXMONTH: 'Desde 6 meses atrás',
						ONEYEAR: 'Desde 1 año atrás',
						BEGINYEAR: 'Desde principios del año',
						FULLDATA: 'Período máximo en el que hay datos'
					},
					TEXT:
					{
						TODAY: 'Hoy',
						ONEWEEK: '1s',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1a',
						BEGINYEAR: 'pa',
						FULLDATA: 'MÁX'
					},

					TOOLTIP: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHAGENT: 'Buscar'
					},
					NOMATCHS: 'no hay coincidencias'
				}
			},
			COMPLETECHAT:
			{
				SCROLLBARX: "Seleccione la franja de tiempo a visualizar en la barra, o seleccione horizontalmente en la gráfica para hacer zoom",
				NOINFO: "No existe información para el período",
				CSV:
				{
					HEADERS:
					{
						AGENTID: "agente_id",
						AGENTEMAIL: "agente_email",
						COUNT: "cantidad",
						DATE: "fecha"
					}
				},
				LABELS:
				{
					FROM: 'Desde:',
					TO: 'Hasta:',
					FILTERAGENTS: 'Filtrar agentes',
					ALLAGENTS: 'Todos los agentes'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos del rango seleccionado',
						TODAYDATA: 'Datos de hoy',
						ONEWEEK: 'Desde 1 semana atrás',
						ONEMONTH: 'Desde 1 mes atrás',
						THREEMONTH: 'Desde 3 meses atrás',
						SIXMONTH: 'Desde 6 meses atrás',
						ONEYEAR: 'Desde 1 año atrás',
						BEGINYEAR: 'Desde principios del año',
						FULLDATA: 'Período máximo en el que hay datos'
					},
					TEXT:
					{
						TODAY: 'Hoy',
						ONEWEEK: '1s',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1a',
						BEGINYEAR: 'pa',
						FULLDATA: 'MÁX'
					},

					TOOLTIP: {
						SEARCH: 'Buscar',
						DOWNLOADDATA: 'Descargar datos'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHAGENT: 'Buscar'
					},
					NOMATCHS: 'no hay coincidencias'
				}
			}
		},
		LEFTPANEL:
		{
			CHROMENOTIFICATION: {
				TITLE: 'Notificación WittyBots',
				NEWCONTACTONQUEUE: 'Ingresó un nuevo contacto a la bandeja ',
				NEWSCONTACTONQUEUEP1: 'Ingresaron ',
				NEWSCONTACTONQUEUEP2: ' nuevos contactos ',

			},
			EVENTMESSAGES:
			{
				SENDTOQUEUEERROR: "No es posible realizar la acción, el contacto ha sido movido o tomado por otro agente",
				SENDTOQUEUEOK: "El contacto ha sido movido de bandeja correctamente",
				LOADOK: "El chat del contacto ha sido cargado correctamente",
				LOADERROR: "No es posible realizar la acción, el contacto ha sido movido o tomado por otro agente",
				CANTCHANGEVIEW: "No es posible cambiar la cantidad de vistas, debe cerrar las conversaciones cargadas en las vistas que quedarán ocultas"
			},
			FIELDS:
			{
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHCONTACT: 'Buscar (al menos 3 caracteres)',
						SEARCHQUEUE: 'Buscar (al menos 3 caracteres)',


					},
					NOMATCH: 'no hay coincidencias'
				}
			},
			BUTTONS:
			{
				TOOLTIP:
				{
					CLICTOLOAD: 'Hacer clic para cargar en la ventana de chat',
					SENDTOQUEUE: 'Enviar a bandeja'
				}
			},
			LABELS:
			{
				USR: 'USR',
				VIEWS: 'Vistas',
				AVAIABLEQUEUES: 'Bandejas disponibles'
			},

			TOOLTIPS:
			{
				QUEUESOTHER: "Bandejas y otros",
			}
		},
		CHAT:
		{
			CHROMENOTIFICATION: {
				METADATA:
				{
					TITLE: 'Notificación WittyBots',
					NEWMESSAGESONCHAT: 'Hay nuevos mensajes en las conversaciones abiertas'
				},
				NOTE:
				{
					TITLE: 'Notificación WittyBots',
					NEWMESSAGESONCHAT: 'Hay nuevos mensajes en las conversaciones abiertas'
				},
				DOCUMENT:
				{
					TITLE: 'Notificación WittyBots',
					NEWMESSAGESONCHAT: 'Hay nuevos mensajes en las conversaciones abiertas'
				},
				IMAGE:
				{
					TITLE: 'Notificación WittyBots',
					NEWMESSAGESONCHAT: 'Hay nuevos mensajes en las conversaciones abiertas'
				},
				INDIVIDUALCHAT:
				{
					TITLE: 'Notificación WittyBots',
					NEWMESSAGESONCHAT: 'Hay nuevos mensajes en las conversaciones abiertas'
				}

			},
			CONFIRMDIALOG:
			{
				CONFIRMMESSAGE: '¿Esta seguro de eliminar este elemento?',
				NO: 'No',
				YES: 'Si',
			},
			MESSAGECHAT:
			{
				USR: "USR",
				AGT: "AGT",
				BOT: "BOT",
				HSM: "HSM",
				LAT: "Lat:",
				LNG: "Lng:"
			},
			INDIVIDUALCHAT:
			{
				SELECTED: 'Seleccionados',
				FORCETOAGENT: 'Enviar a modo agente',
				EVENTMESSAGES:
				{
					SENDTOQUEUEOK: 'El contacto ha sido enviado a la bandeja correctamente',
					CLOSEOK: 'El chat ha sido cerrado correctamente y el contacto ha vuelto al modo bot',
					BACKOK: 'El contacto ha sido devuelto a la bandeja de origen correctamente'
				},
				DRAGDROPLABEL: 'Puede arrastrar un contacto de la bandeja aquí',
				INPUTS:
				{
					TOOLTIPS:
					{
						WRITEHERE: 'Escribir aquí...',
						UNREADEDMESSAGES: 'Mensajes sin leer'
					}

				},
				BUTTON:
				{
					TOOLTIPS: {
						CALL: 'Llamar al contacto',
						CLOSECHAT: 'Cerrar y quitar modo agente',
						SHOWNOTES: 'Ver notas del contacto',
						SHOWMETA: 'Ver perfil del contacto',
						BACKTOQUEUE: 'Devolver a bandeja de origen',
						SENDTOQUEUE: 'Enviar a bandeja',
						SENDIMAGE: 'Enviar imagen',
						MOREACTIONS: 'Más acciones',
						SENDDOCUMENT: 'Enviar documento',
						SENDVOICE: 'Enviar audio de voz',
						SEND: 'Enviar',
						STOPRECORDING: 'Detener grabación',
						CANCELRECORDING: 'Cancelar grabación',
						SENDFORWARD: 'Reenviar mensajes',
						CANCELFORWARD: 'Cancelar reenvío',
						PLEASETALKNOW: 'Por favor hable ahora ...'
					}
				},
				SELECT:
				{

					PLACEHOLDER:
					{
						SEARCH: 'Buscar'
					},
					NOMATCHS: 'No hay coincidencias'


				}
			},

			SETFORWARDUSERS:
			{
				LABEL:
				{

					SELECTUSERS: 'Seleccionar contactos',
					FORWARD: 'Reenviar',
					MESSAGESTO: 'mensajes a',
					SETAGENTMODE: 'Setear en modo agente',
					SELECTQUEUE: 'Seleccionar bandeja',
					CONFIRM: 'Aceptar',
					MESSAGEFORWARDERBY: 'Mensaje reenviado por',
					STARTFORWARDING: 'Comenzando reenvio de mensajes',
					ENDFORWARDING: 'Los mensajes han sido reenviados'
				}
			},
			DOCUMENTS:
			{
				TITLE: 'Envío de documento',
				DRAGLABEL: 'Arrastre el archivo o haga click para seleccionarlo',
				SENDLABEL: 'Enviar',
				SENDLABELTOOLTIP: 'Enviar documento'

			},
			IMAGES:
			{
				TITLE: 'Envío de imagen',
				DRAGLABEL: 'Arrastre el archivo o haga click para seleccionarlo',
				SENDLABEL: 'Enviar',
				SENDLABELTOOLTIP: 'Enviar documento',
				PLACEHOLDERCAPTION: 'Ingrese el caption'

			},
			NOTES:
			{
				EVENTMESSAGES:
				{
					DELETENOTEOK: "Nota eliminada correctamente.",
					DELETENOTEERROR: 'Error al eliminar nota',
					SAVENOTEOK: 'Nota guardada correctamente.',
					SUBJECTNOTBLANK: 'El campo asunto no puede ser vacío',
					SUBJECTNOTCONTAINRESERVED: 'El campo asunto no puede tener el texto reserved_',
					THESUBJECT: 'El asunto',
					EXIST: 'ya existe',
					CREATENOTEOK: 'Nota creada correctamente',
					CREATENOTEERROR: 'Error al crear nota, el asunto no puede repetirse'


				},
				LABEL:
				{
					CREATENOTE: 'Crear nota:',
					CONTACTNOTE: 'Notas del contacto',
					NONOTES: 'No hay notas creadas'
				},
				FIELDS:
				{
					PLACEHOLDER: {
						VALUE: "Valor",
						NOTE: "Nota",
						SUBJECT: 'Asunto'
					},
					SELECT:
					{
						PLACEHOLDER:
						{
							SEARCH: "Buscar por asunto (al menos 3 caracteres)"
						}
					},
					LABELS:
					{
						SUBJECT: 'Asunto',
						NOTE: 'Nota',
						USER: 'Usuario',
						DATE: 'Fecha',
						ACTIONS: 'ACciones'

					}
				},
				BUTTONS: {
					TOOLTIP: {
						CREATE: 'Crear',
						DELETE: 'Borrar',
						EDIT: 'Editar',
						SAVE: 'Guardar',
						CANCEL: 'Cancelar'
					}
				}

			},
			METADATA:
			{
				EVENTMESSAGES:
				{
					DELETEMETAOK: "Meta-data eliminada correctamente.",
					DELETEMETAERROR: 'Error al eliminar meta-data',
					SAVEMETAOK: 'Meta-data guardada correctamente.',
					KEYNOTBLANK: 'El campo clave no puede ser vacío',
					KEYNOTCONTAINRESERVED: 'El campo clave no puede tener el texto reserved_',
					THEKEY: 'La clave',
					EXIST: 'ya existe',
					CREATEMETAOK: 'Meta-data creada correctamente',
					CREATEPROFILEOK: 'Campo de perfil creado correctamente',
					CREATEMETAERROR: 'Error al crear meta-data, la clave no puede repetirse'


				},
				LABEL:
				{
					CREATEMETA: 'Crear meta-data:',
					CREATEPROFILE: 'Crear nuevo campo de perfil:',
					CONTACTMETA: 'Meta-data del contacto',
					NOMETA: 'No hay meta-datas creadas',
					NOMETAPROFILE: 'No hay más datos de perfil creados'
				},
				FIELDS:
				{


					PLACEHOLDER: {
						VALUE: "Valor",
						FIELD: 'Ingrese el nombre del campo',
						DATA: 'Ingrese el dato',
						KEY: 'Clave'
					},
					SELECT:
					{
						PLACEHOLDER:
						{
							SEARCH: "Buscar por clave (al menos 3 caracteres)",
							SEARCHPROFILE: "Buscar por campo (al menos 3 caracteres)"
						}
					},
					LABELS:
					{
						KEY: 'Clave',
						VALUE: 'Value',
						USER: 'Usuario',
						DATE: 'Fecha',
						ACTIONS: 'Acciones',
						FIELD: 'Campo',
						DATA: 'Dato',


					}
				},
				BUTTON: {
					ADD: "Agregar",
					TOOLTIP: {
						ADD: 'Agregar',
						DELETE: 'Borrar',
						EDIT: 'Editar',
						SAVE: 'Guardar',
						CANCEL: 'Cancelar'
					}
				}

			}
		},
		PROFILE:
		{
			TAB:
			{
				TITLE:
				{
					PERSONALQUEUE: 'Bandeja personal',
					PASSWORD: 'Contraseña'
				}
			},
			EDIT:
			{
				FIELD:
				{
					LABEL:
					{
						OLDPASSWORD: 'Contraseña anterior',
						OLDPASSWORDREQ: 'La contraseña anterior es requerida',
						NEWPASSWORD: 'Contraseña nueva',
						NEWPASSWORDREQ: 'La contraseña nueva es requerida',

						NEWPASSWORD2: 'Reingresar nueva contraseña',
						NEWPASSWORDREQ2: 'El reingreso de la nueva contraseña es requerido',

					},
					PLACEHOLDER:
					{
						QUEUECLOSED: 'Conversaciones cerradas',
						QUEUEGENERAL: 'General',
						QUEUEPERSONAL: 'Personal',
						QUEUEINTERMEDIATE: 'Intermedia',
						QUEUEEXPIRED: 'Conversaciones expiradas',
					}

				}
			}
		},
		AGENTS:
		{
			TITLE:
			{
				PROFILE: 'Perfil'
			},
			EDIT:
			{
				ERRORTRYAGAIN: 'Ha ocurrido un error intente nuevamente',
				FIELD:
				{
					PLACEHOLDER:
					{
						ID: 'Id',
						TYPEHERELEAVEBLANK: 'Ingrese aquí o deje vacío para no enviar'
					},
					LABEL:
					{
						ROL: 'Rol:',
						USER: 'Usuario:',
						EMAIL: 'Email:',
						ACTIVE: 'Activo',
						FIRSTNAME: 'Nombre:',
						LASTNAME: 'Apellido:',
						CANRESTORE: 'Puede restaurar conversación:',
						CANDOWNLOAD: 'Descargar conversación al finalizar:',
						TILESONCHAT: 'Cuadriculas en chat',
						WELCOMEMESSAGE: 'Mensaje de bienvenida',
						GOODBYEMESSAGE: 'Mensaje de despedida',
						LASTLOGIN: 'Último login:',
						CREATED: 'Creado:',
						BY: 'por:',
						UPDATED: 'Actualizado:'

					}
				},
				BUTTON:
				{
					BACK: 'Volver',
					SAVE: 'Guardar',
					TOOLTIP:
					{
						SAVE: 'Guardar datos'
					}
				},
				TAB:
				{
					TITLE:
					{
						BASICINFO: 'Información básica',
					}
				}
				,
				TOOLTIP:
				{
					SAVE: 'Guardar'
				}
			},
			EVENTMESSAGES:
			{
				EDITING: 'Editando',
				SENDRESETPASSWORDOK: 'La clave de usuario fue reseteada y enviada al email del usuario correctamente',
				CREATEAGENTOK: 'El agente fue creado correctamente.',
				SAVEAGENTOK: 'El agente fue guardado correctamente.',
				PASSWORDNOTMATCH: 'ERROR: La nueva contraseña y su reingreso no coinciden',
				WRONGOLDPASSWORD: 'ERROR: La contraseña anterior no es correcta',
				PASSWORDCHANGEOK: 'La contraseña fue guardada correctamente.'

			},
			TABLE:
			{
				PLEASEWAIT: 'Por favor espere',
				NORECORDS: 'No hay registros',
				LABELS:
				{
					AGENT: 'Agente',
					SUPERAGENT: 'Agente Supervisor'
				},
				FILTER:
				{
					PLACEHOLDER: {
						SEARCHBYAGENT: 'Buscar por nombre de usuario (al menos 3 caracteres)'
					}
				},
				HEADER:
				{
					ID: 'Id',
					ROL: 'Rol',
					USER: 'Usuario',
					FIRSTNAME: 'Nombre',
					LASTNAME: 'Apellido',
					LASTLOGIN: 'Último login',
					ACTIVE: 'Activo',
					ACTIONS: 'Acciones',
					RESETPASSWORD: 'Resetear clave'

				},
				ACTIONS:
				{
					TOOLTIP:
					{
						EDITAGENT: 'Editar agente',
						RESETPASSWORD: 'Resetear clave'
					}
				}
			},
			PAGE:
			{
				TITLE:
				{
					USERLIST: 'Lista de usuarios'
				}
				,
				BUTTON:
				{
					NEWAGENT: 'Nuevo agente'
				},
				TOOLTIP:
				{
					CREATENEWAGENT: 'Crear nuevo agente'
				}
			}
		},
		MANAGE:
		{
			TITLE: 'Administración'
		},

		FORM:
		{
			TITLE: 'Formularios',
		},
		PROCESS:
		{
			TITLE: 'Procesos',
			EDIT: {
				EDITPROCESS: 'Editar proceso',
				FLOW: 'Flujo',
				SAVE: 'Guardar',
				BASICINFO: 'Información básica',
				BACK: 'Volver',
				TOOLTIP:
				{
					BACKTOPROCESSLIST: 'Volver a la lista de procesos'
				}
			}
			,
			LIST: {
				HEADER:
				{
					ACTIONS: 'Acciones'
				},
				TOOLTIP:
				{
					CREATENEWPROCESS: 'Crear nuevo proceso',

					EDITPROCESS: 'Editar proceso',
					DELETEPROCESS: 'Eliminar proceso'
				},
				TABLE: {
					ID: 'Id',
					NORECORDS: 'No se encontraron procesos',
					NAME: 'Nombre',
					PLEASEWAIT: 'Por favor espere....',
					DESCRIPTION: 'Descripción',
					CREATED: 'F. creación',
					CREATEDBY: 'Creador',
					NUMACTIVETOTAL: 'Nro. Activos/Total',
					NUMSTAGES: 'Nro. Etapas',
					ACTIVE: 'Activo',
					NUMQUEUES: 'Nro. Bandejas',
					ACTIONS: 'Acciones',

				},
				NEWPROCESS: "Nuevo proceso",
				TITLE: "Lista de procesos",

			}
		},
		QUEUES:
		{
			TOOLTIP:
			{
				CREATENEWQUEUE: 'Crear nueva bandeja'
			},
			PLACEHOLDER:
			{
				SEARCHBYNAME: 'Buscar por nombre (al menos 3 caracteres)'
			},
			QUEUE:
			{
				EVENTMESSAGES:
				{
					CREATEOK: 'La nueva bandeja fue creada correctamente.',
					SAVEOK: 'La bandeja fue guardada correctamente.'
				},
				QUEUEDATA: 'Datos de la bandeja',
				NAMEIS: "El nombre es",
				REQUIERED: "requerido",
				PLEASEENTEREL: "Por favor ingrese el",
				PLEASEENTERLA: "Por favor ingrese la",
				ENTERLA: "Ingrese la",
				ENTEREL: "Ingrese el",
				NAME: "nombre",
				ID: "Id",
				CREATEDUSER: "Creado por",
				CREATEDTIMESTAMP: "Fecha creación",
				DESCRIPTION: "descripción",
				ACTIVE: "Activo",
				YES: "SI",
				NO: "NO",
				WELCOMEMESSAGE: "mensaje de bienvenida",
				TYPE: "Tipo",
				TYPEINIT: "Inicial",
				TYPEINTER: "Intermedia",
				TYPEPERS: "Personal",
				SAVE: 'Guardar',
				RESET: 'Resetear',
				BACK: 'Volver',
				TOOLTIP: {
					SAVE: 'Guardar',
					BACKTOQUEUELIST: 'Volver al listado de bandejas',
					SAVECONTINUE: 'Guardar y continuar',
					RESETECHANGES: 'Borrar cambios'
				},
				ERRORTRYAGAIN: 'Ha ocurrido un error modifique los datos e intente nuevamente',
				BASICINFO: 'Información básica',
				AGENTS: 'Agentes',
				EDITQUEUE: 'Editar bandeja',
				AGENTTAB:
				{
					EVENTMESSAGES:
					{
						ASSIGNAGENTOK: 'Lo agentes fueron viculados correctamente'
					},
					AVAIABLETITLE: 'Agentes disponibles',
					SELECTEDTITLE: 'Agentes asignados',
					SEARCHPLACEHOLDER: 'Buscar agente',
					TITLE: 'Asignación de agentes'
				},
				REDIRECTS:
				{
					EVENTMESSAGES:
					{
						SAVEOK: 'Las redirecciones fueron guardadas correctamente.'

					},
					OPERATORS:
					{
						CONTAIN: 'Contiene',
						NOCONTAIN: 'No Contiene',
						DISTINCT: 'Distinto',
						EQUAL: 'Igual',
						GREATER: 'Mayor',
						GREATEREQUAL: 'Mayor igual',
						LESS: 'Menor',
						LESSEQUAL: 'Menor igual',
					},
					LABELS:
					{
						NOREDIRECTS: 'No hay redirecciones por meta-data creadas.',
						NEW: 'Nueva',
						NOMATCHS: 'No hay coincidencias'
					},
					PLACEHOLDER:
					{
						SELECTSEARCH: 'Buscar'
					},
					TOOLTIP:
					{
						CREATENEWREDIRECT: 'Crear nueva redirección por meta-data',
						DELETEREDIRECT: 'Eliminar redirección por meta-data'
					},
					REDIRECTS: 'Redirecciones',
					TIMEOUT:
					{
						TIMEOUTTITLE: 'Tiempo de espera',
						DESTINATIONQUEUE: 'Destino',
						REDIRECTMESSAGE: 'Mensaje al redireccionar',
						TIMEOUT: 'Tiempo de espera',
						SECONDS: 'segundos',
						ACTIVE: 'Activo'
					},
					WITHOUTAGENT:
					{
						WITHOUTAGENTTITLE: 'Sin agentes',
						DESTINATIONQUEUE: 'Destino',
						REDIRECTMESSAGE: 'Mensaje al redireccionar',
						ACTIVE: 'Activo',
						ONLYNEWARRIVES: 'Solo aplicable a nuevos arribos'
					},
					META:
					{
						OPERATORREQUIERED: 'Debe seleccionar un operador',
						OPERATOR: 'Operador',
						KEY: 'Clave',
						VALUE: 'Valor',
						METATITLE: 'Meta-data',
						DESTINATIONQUEUE: 'Destino',
						REDIRECTMESSAGE: 'Mensaje al redireccionar',
						DESTINATIONQUEUEREQUIERED: 'Debe seleccionar la bandeja de destino'
					}

				}
			},
			TABLE: {
				HEADER:
				{
					ACTIONS: 'Acciones'
				},
				NEWQUEUE: "Nueva bandeja",
				QUEUESLIST: "Lista de bandejas",
				ITEMSPERPAGE: "Bandejas por página",
				NEXTPAGE: "Página siguiente",
				PREVPAGE: "Página anterior",
				ID: 'Id',
				NORECORDS: 'No se encontraron bandejas',
				NAME: 'Nombre',
				PLEASEWAIT: 'Por favor espere....',
				DESCRIPTION: 'Descripción',
				ACTIVE: 'Activa',
				ACTIONS: 'Acciones',
				TOOLTIP: {
					EDITQUEUE: 'Editar bandeja',
					DELETEQUEUE: 'Eliminar bandeja'
				}
			}
		},
		AUTH: {
			BIENVENIDA: {
				BIENVENIDO: 'Bienvenido a Witty Agent v2',
				DESCRIPCION: 'La aplicación multi-agente de Witty Bots'

			},
			POLITICA: {
				LABEL: 'Política',
				LINK: ''

			},

			CONTACTO: {
				LABEL: 'Contacto',
				LINK: ''

			},

			LEGAL: {
				LABEL: 'Legal',
				LINK: ''

			},
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Se te olvidó tu contraseña',
				BACK_BUTTON: 'Espalda',
				PRIVACY: 'Intimidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				BIENVENIDO: 'Bienvenido',
				TITLE: 'Crear una cuenta',
				BUTTON: 'Registrarse',
				LOGIN: 'Iniciar sesión',
				EVENTMESSAGES:
				{
					MAXUSERREACHED: 'Error: Se alcanzó el número máximo de agentes en simultáneo'
				}
			},
			FORGOT: {
				TITLE: 'Contraseña olvidada?',
				DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Usuario'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',

				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		}

	}
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//local
/*
export const environment = {
//baseApiUrl:"https://multiagent.wittybots.uy/api",
//baseApiUrl:"https://multiagent.test.wittybots.uy/api",
	baseApiUrl:"http://localhost/multiagent/api",
	production: true,
	version:"1.0.1",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'
};*/
//tyntec
/*
export const environment = {
	production: true,
	loginlogourl:"./assets/media//bg/bg-4-tyntec.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6-tyntec.png",
	facebooklink:"https://www.facebook.com/tyntec/",
	linkedinlink:"https://www.linkedin.com/company/tyntec/",
	whatsapplink:"https://wa.me/4989202451100?text=Hi",
	twitterlink:"https://twitter.com/tyntec",
	clientfullname:"Tyntec",
	clientweburl:"https://www.tyntec.com",
	clientcontacttourl:"mailto:sales@tyntec.com",
	clientabouturl:"https://www.tyntec.com/about-tyntec",
	//baseApiUrl:"http://localhost/multiagent/api",
	baseApiUrl:"https://multiagent.test.wittybots.uy/api",
	version:"1.1.0",
	client:"tyntec",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/
//test
/*

export const environment = {
	
	socketChatUrl:"wss://multiagent.test.wittybots.uy:8082",
	//socketChatUrl:"wss://socketagent.wittybots.uy",
	loginlogourl:"./assets/media//bg/bg-4.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6.png",
	facebooklink:"https://www.facebook.com/wittybots/",
	linkedinlink:"https://www.linkedin.com/company/11449604",
	whatsapplink:"https://api.whatsapp.com/send?phone=59892462479",
	clientfullname:"WittyBots",
	clientweburl:"https://www.wittybots.uy",
	clientcontacttourl:"mailto:soporte@simpletech.com.uy",
	clientabouturl:"https://www.wittybots.uy/acerca-de",
	production: true,
	baseApiUrl:"http://multiagent.test.wittybots.uy/api",
	baseAdapterUrl:"https://client.test.wittybots.uy/production/",
	//baseApiUrl:"https://multiagent.wittybots.uy/api",
	version:"1.1.0",
	client:"witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey:'adas98jadad98ajdasjd',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/
/*


//frank

export const environment = {
	
	loginlogourl:"./assets/media//bg/bg-4.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6.png",
	facebooklink:"https://www.facebook.com/wittybots/",
	linkedinlink:"https://www.linkedin.com/company/11449604",
	whatsapplink:"https://api.whatsapp.com/send?phone=59892462479",
	twitterlink:"#",
	clientfullname:"WittyBots",
	clientweburl:"https://www.wittybots.uy",
	clientcontacttourl:"mailto:soporte@simpletech.com.uy",
	clientabouturl:"https://www.wittybots.uy/acerca-de",
	clientBy:"WittyBots by SimpleTECH",
	clientByUrl:"https://www.wittybots.uy/",
	
	production: true,
	//baseAdapterUrl:"https://corealb.wittybots.uy/production/",
	
	baseApiUrl:"https://multiagent.wittybots.uy/api",
	version:"1.2.9",
	client:"witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey:'adas98jadad98ajdasjd',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};

*/
//prod

export const environment = {
	socketChatUrl: "wss://socketagent.wittybots.uy",

	loginlogourl: "./assets/media//bg/bg-4.jpg",
	headerlogourl: "../../../assets/media/logos/logo-6.png",
	facebooklink: "https://www.facebook.com/wittybots/",
	linkedinlink: "https://www.linkedin.com/company/11449604",
	whatsapplink: "https://wa.me/59827140301",
	twitterlink: "#",
	clientfullname: "WittyBots",
	clientweburl: "https://www.wittybots.uy",
	clientcontacttourl: "mailto:soporte@simpletech.com.uy",
	clientabouturl: "https://www.wittybots.uy/acerca-de",
	clientBy: "WittyBots by SimpleTECH",
	clientByUrl: "https://www.wittybots.uy/",
	//baseAdapterUrl:"https://corealb.wittybots.uy/production/",
	production: true,
	baseApiUrl: "https://multiagent.wittybots.uy/api",
	version: "1.4.6",
	client: "witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey: 'adas98jadad98ajdasjd',
	inviteWEBRTCUrl: 'https://webrtc.wittybots.uy:8888/client2/examples/demo/streaming/video-chat-and-screen-sharing/video-chat-and-screen-sharing.html',
	VAPID_PUBLIC_KEY: 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};

//preprod
/*
export const environment = {
	socketChatUrl:"wss://socketagentpreprod.wittybots.uy",
	loginlogourl:"./assets/media//bg/bg-4.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6.png",
	facebooklink:"https://www.facebook.com/wittybots/",
	linkedinlink:"https://www.linkedin.com/company/11449604",
	whatsapplink:"https://api.whatsapp.com/send?phone=59892462479",
	twitterlink:"#",
	clientfullname:"WittyBots",
	clientweburl:"https://www.wittybots.uy",
	clientcontacttourl:"mailto:soporte@simpletech.com.uy",
	clientabouturl:"https://www.wittybots.uy/acerca-de",
	clientBy:"WittyBots by SimpleTECH",
	clientByUrl:"https://www.wittybots.uy/",
	baseAdapterUrl:"https://client2preprod.wittybots.uy/production/",
	production: true,
	baseApiUrl:"https://multiagentpreprod.wittybots.uy/api",
	version:"1.3.6",
	client:"witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey:'adas98jadad98ajdasjd',
	inviteWEBRTCUrl:'https://webrtc.wittybots.uy:8888/client2/examples/demo/streaming/video-chat-and-screen-sharing/video-chat-and-screen-sharing.html',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in prod  uction mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

export class User extends BaseModel {
    id: number;
    botId: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    rol: string;
    extension:string=null;
    pin:string=null;
    extensionPassword:string=null;
    canRestoreConversation:boolean;
    pic: string;
    firstName: string;
    lastName: string;
    occupation: string;
    phone: string;
    active:number;
    lastLogin:number;
    createdTimestamp:number;
    createdUserId:number;
     updateTimestamp:number;
    updateUserId:number;
    welcomeMessage:string;
    goodByeMessage:string;
    onCloseWH:string;
    views:number;
    firePushQueueIds:string[]=[];
    mantainConversationOnExit:boolean;
    downloadConversationOnLeave:boolean=false;
    clientTarget:string;
    
    clear(): void {
        this.id = 0;
        this.firePushQueueIds=[];
        this.botId = 0;
        this.canRestoreConversation=false;
        this.username = '';
        this.password = '';
        this.email = '';
        this.welcomeMessage='';
        this.goodByeMessage='';
        this.views=1;
        this.mantainConversationOnExit=true;
        this.active=1;
        this.onCloseWH='';
        this.rol = 'Agente';
        this.firstName = '';
        this.lastName = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.pic = '';
        this.occupation = '';
        this.lastLogin=0;
        this.updateTimestamp=0;
        this.updateUserId=0;
        this.createdTimestamp=0;
        this.createdUserId=0;
        this.phone = '';
        this.downloadConversationOnLeave=false;
        
    }
}

import { BaseModel } from '../../_base/crud';
import { Cant_String} from './cant_string.model';

export class QueueStatString  extends BaseModel {
	id:number;
	name:string;
	data:Cant_String[];

	clear() {

		this.id=undefined;
		this.name="";
		this.data=[];
	
		
	
	}
	
		
}

// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';
import { environment } from './../../../../environments/environment.prod';
@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	// Public properties
	
	today: number = Date.now();
	fluid: boolean;
	env:any;
	appVersion:string;
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
		this.appVersion = environment.version;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
	this.env=environment;
		// footer width fluid
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
	}
	getClientFullName()
	{
		return environment.clientBy;
	}
	getClientWEBUrl()
	{
		return environment.clientByUrl;
	}
	getClientContactToUrl()
	{
		return environment.clientcontacttourl;
	}
	getClientAboutUrl()
	{
		return environment.clientabouturl;
	}
}

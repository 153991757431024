// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap, map, first } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { MessageChatModel } from '../_models/message-chat.model';
import { PushNotificationOptions, PushNotificationService } from 'ngx-push-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MetaDataModel } from '../_models/metaData-chat.model';
import { QueueStat } from '../_models/queueStat.model';
import { QueueStatString } from '../_models/queueStatString.model';
import { QueueModel } from '../_models/Queue.model';
import { NoteModel } from '../_models/note-chat.model';

import { Router } from '@angular/router';
import { QueueFinalClientModel } from '../_models/queue-finalclient.model';
import { environment } from '../../../../environments/environment';
import { Cant_String } from '../_models/cant_string.model';
import { Cant_Date } from '../_models/cant_date.model';
import { CdkRow } from '@angular/cdk/table';
import { AuthService } from '../../auth/_services/auth.service';



const API_CHAT_URL = environment.baseApiUrl + '/agent.php/chats';
const API_QUEUE_URL = environment.baseApiUrl + '/agent.php/queues';

export interface StatusIdModel {
	id: number;
	status: number;
	statusTime: number;

}
export interface StatusInputModel {
	firstId: number;

	statusTime: number;
	chatUserId: string;


}
export interface MessageIdModel {
	id: number;
	status: number;
	statusTime: number;

}
export interface MessageInputModel {
	interactionId: number;

	lastMessageId: number;
	chatUserId: string;


}

export interface QueueInputModel {

	queueId: number;
	typeQueue: number;

}
// Real REST API
@Injectable()
export class ChatService implements OnDestroy {
	userViews: number = 1;
	statusModelResults: Array<any> = null;
	statusModelInputs: Array<StatusInputModel>;
	queuesModelInputs: QueueInputModel[];
	queuesModelResults: Array<any> = null;
	queueFinalClientModelLoaded: QueueFinalClientModel[];
	messageModelResults: Array<any> = null;
	messageModelInputs: Array<MessageInputModel>;
	statusTime: any = "0";
	userId: Number;
	timrecon: any;
	timeping: any;
	imOnline: String;
	isInChatView: boolean = true;
	retryconnect = true;
	retryconnectOnClose = true;
	reloaddata = false;
	queueList: boolean[] = [];
	audio = new Audio("./assets/media/sounds/1.mp3");
	audio2 = new Audio("./assets/media/sounds/2.mp3");
	connectionSocket: any;
	chatComponent: any;
	timerPong: any;
	socketReOpened: boolean = false;
	lastInteractionId: number = null;


	private res: QueryResultsModel = new QueryResultsModel();

	constructor(private http: HttpClient, private translate: TranslateService, private _pushNotificationService: PushNotificationService,
		private httpUtils: HttpUtilsService, private router: Router, private authService: AuthService) {


		this.imOnline = sessionStorage.getItem("userId");
		let us = sessionStorage.getItem("userId");
		if (us != null && us != "")
			this.setUserId(Number(us));
		else
			this.setUserId(null);

		let uv = sessionStorage.getItem("userViews");
		if (uv != "" && uv != null) {
			this.userViews = Number(uv);
		}

	}

	public setIsInChatView(is: boolean) {
		if (this.isInChatView == false && is == true) {
			this.isInChatView = is;
			this.callChatServer();

		}
		else

			this.isInChatView = is;

	}


	public ping(self): void {
		var obj = {
			'event': 'ping'
		};
		if (self.connectionSocket == null || self.connectionSocket.readyState === WebSocket.CLOSED) {
			if (self.retryconnect) {
				if (self.chatComponent !== undefined)
					self.chatComponent.noInternetMessage();
				console.log("Error on send ping to socket");
				//window.location.reload();
				self.retryconnect = true;
				self.socketReOpened = true;


				clearTimeout(self.timeping);
				clearTimeout(self.timerPong);
				clearTimeout(self.timrecon);
				self.tryOpenConn(self, self.chatComponent);
			}
			else
				console.log("no retry connection");

		}
		else {
			self.connectionSocket.send(JSON.stringify(obj));
			clearTimeout(self.timerPong);
			//self.timerPong=setTimeout(function() { 	console.log("closing halfopen socket"); self.connectionSocket.close(); setTimeout(function(){self.ping(self);}, 5000);}, 5000);
			self.timerPong = setTimeout(function () { console.log("closing halfopen socket"); self.connectionSocket = null; setTimeout(function () { self.ping(self); }, 5000); }, 5000);
		}
	}

	public delay(ms: number): any {

		return new Promise(resolve => setTimeout(resolve, ms));
	}
	public reloadTimmersAndPing(self: any): void {
		self.retryconnect = true;
		clearTimeout(self.timeping);
		clearTimeout(self.timerPong);
		clearTimeout(self.timrecon);

		self.timeping = setTimeout(function () { self.ping(self) }, 5000);


	}
	public tryOpenConn(selfOr: any, chatComp: any): void {
		let self: any;
		if (selfOr == null)
			self = this;
		else
			self = selfOr;

		self.authService.checkOnline().subscribe(data => {


			if (data == true) {
				self.retryconnect = true;
				if (chatComp != null) {
					//your task after delay.


					self.constructStatusModelResults();
					self.constructStatusModelInputs();
					self.constructMessageModelResults();
					self.constructMessageModelInputs();
					self.constructQueueModelResults();
					self.constructQueueModelInputs();

					self.callChatServer();

					self.chatComponent = chatComp;
					console.log("try reload data");
					if (self.socketReOpened == true) {
						console.log(" reload data");
						self.chatComponent.reloadAllAfterLostConnection();

					}
				}
				if (self.connectionSocket == null || self.connectionSocket.readyState === WebSocket.CLOSED) {
					if (sessionStorage.getItem('userId') != null && sessionStorage.getItem('userId') != undefined
						&& sessionStorage.getItem(environment.authTokenKey) != null && sessionStorage.getItem(environment.authTokenKey) != undefined) {

						let userId = sessionStorage.getItem('userId');
						let token = sessionStorage.getItem(environment.authTokenKey);
						self.retryconnectOnClose = false;

						this.authService.getTarget().subscribe(result => {
							let socketUrl = result.socketAgentEndpoint;
							let socketTarget = result.target;
							let socketTargetGroup = result.targetGroup;


							self.connectionSocket = new WebSocket(socketUrl + "?target=" + socketTarget + "&targetgroup=" + socketTargetGroup + "&token=" + token);

							self.connectionSocket.onopen = function (e) {
								//self.openConnection();
								if (self.socketReOpened == true)
									self.chatComponent.reconnectMessage();

								self.socketReOpened = false;
								if (self.reloaddata) {
									console.log("must reload data");


								}
								self.reloadTimmersAndPing(self);

							};

							self.connectionSocket.onmessage = function (e) {
								var msg_array = JSON.parse(e.data);

								self.reloadTimmersAndPing(self);

								switch (msg_array.command) {
									case 'logout': {
										clearTimeout(self.timrecon);
										clearTimeout(self.timerPong);
										clearTimeout(self.timrecon);
										self.retryconnectOnClose = false;
										self.retryconnect = false;
										console.log("Force logout where token change (another login with same user)");
										sessionStorage.removeItem(environment.authTokenKey);
										self.router.navigate(['/auth/login']);
										break;
									}
									case 'new_message': {



										let finalClientInteractionId = msg_array.data.finalClientInteractionId;
										let message: string = msg_array.data.message;
										let messageType: string = msg_array.data.messageType;
										let messageId: number = msg_array.data.convId;
										let intId: number = msg_array.data.finalClientInteractionId;
										let arrived: Date = new Date(msg_array.data.date);
										let fromType: string = msg_array.data.fromType;

										if (self.chatComponent != null) {
											self.chatComponent.individualChats.forEach(element => {

												if (element.queueFinalClientInteractionId == finalClientInteractionId)
													element.getNewsMessages(element, message, messageType, messageId, intId, arrived, fromType);
											});
										}
										else {
											self.notifMessage(null);

										}

										break;
									}
									case 'new_contact':
										{

											let queueId = msg_array.data.queueId;
											let displayName = msg_array.data.displayName;
											let chatUserId = msg_array.data.chatUserId;
											let finalClientInteractionId = msg_array.data.finalClientInteractionId;
											let message = msg_array.data.message;


											if (self.chatComponent != null) {
												if (self.queueList[queueId] !== undefined && self.queueList[queueId] != null)
													self.chatComponent.sticky.reloadQueue(queueId, "new", chatUserId, finalClientInteractionId, message, displayName);
											}
											else {

												if (sessionStorage.getItem("notShowing") == "true") {
													if (self.queueList[queueId] != undefined && self.queueList[queueId] != null)
														self.getQueueById(queueId).subscribe(data => {

															self.notifContact(1, data.name);

														});
												}

											}
											break;
										}
									case 'update_contact':
										{

											let queueId = msg_array.data.queueId;
											let chatUserId = msg_array.data.chatUserId;
											let finalClientInteractionId = msg_array.data.finalClientInteractionId;
											let message = msg_array.data.message;
											if (this.lastInteractionId == null || finalClientInteractionId <= this.lastInteractionId)
												if (self.chatComponent != null)
													self.chatComponent.sticky.reloadQueue(queueId, "update", chatUserId, finalClientInteractionId, message);
											break;
										}
									case 'remove_contact':
										{
											let chatUserId = msg_array.data.chatUserId;
											let finalClientInteractionId = msg_array.data.finalClientInteractionId;

											let queueId = msg_array.data.queueId;
											if (this.lastInteractionId == null || finalClientInteractionId <= this.lastInteractionId)
												if (self.chatComponent != null)
													self.chatComponent.sticky.reloadQueue(queueId, "remove", chatUserId, finalClientInteractionId, null);
											break;
										}
									case 'load_chat':
										{

											if (self.chatComponent != null) {
												let chatUserId: string = msg_array.data.chatUserId;
												//self.chatComponent.loadChatTiles();
												self.chatComponent.closeChatFromSocket(chatUserId);
												//self.chatComponent.checkUpdateTile();


											}
											break;
										}
									case 'update_status':
										{
											let convId = msg_array.data.convId;
											let chatUserId = msg_array.data.chatUserId;
											let status = msg_array.data.status;

											if (self.chatComponent != null)
												self.chatComponent.individualChats.forEach(element => {


													if (element.queueFinalClientInteraction != null && element.queueFinalClientInteraction.finalClientId == chatUserId)
														element.changeStatus(convId, status);
												});

											break;
										}
								}

							};
							self.connectionSocket.onclose = function () {
								// Try to reconnect in 5 seconds
								//if(self.chatService.getIsInChatView())
								console.log("Socket closed");
								if (self.retryconnectOnClose && (self.connectionSocket == null || self.connectionSocket.readyState === WebSocket.CLOSED)) {
									console.log("try reconnect");
									self.reloaddata = true;

									self.socketReOpened = true;
									self.chatComponent.noInternetMessage();
									clearTimeout(self.timrecon);
									clearTimeout(self.timerPong);
									clearTimeout(self.timrecon);

									self.timrecon = setTimeout(function () { self.tryOpenConn(self, self.chatComponent) }, 5000);
								}
								else {
									self.retryconnectOnClose = true;
									setTimeout(function () { self.ping(self); }, 5000);
									console.log("no try reconnect, another socket opened");
								}
							};
							self.connectionSocket.onerror = function () {
								// Try to reconnect in 5 seconds
								//if(self.chatService.getIsInChatView())
								console.log("Socket error or internet ip change");
								if (self.retryconnectOnClose && (self.connectionSocket == null || self.connectionSocket.readyState === WebSocket.CLOSED)) {
									console.log("try reconnect");

									self.reloaddata = true;
									self.socketReOpened = true;
									clearTimeout(self.timerPong);
									clearTimeout(self.timrecon);
									self.retryconnect = true;
									clearTimeout(self.timrecon);
									self.timrecon = setTimeout(function () { self.tryOpenConn(self, self.chatComponent) }, 5000);
								}

								else {
									self.retryconnectOnClose = true;
									setTimeout(function () { self.ping(self); }, 5000);
									console.log("no try reconnect, another socket opened");
								}
							};
						});
					}
					else {
						console.log("Socket error");
						if (self.retryconnectOnClose && (self.connectionSocket == null || self.connectionSocket.readyState === WebSocket.CLOSED)) {
							self.reloaddata = true;
							self.socketReOpened = true;
							clearTimeout(self.timerPong);
							clearTimeout(self.timrecon);

							clearTimeout(self.timrecon);
							self.timrecon = setTimeout(function () { self.tryOpenConn(self, self.chatComponent) }, 5000);
						}
						else {
							setTimeout(function () { self.ping(self); }, 5000);
							self.retryconnectOnClose = true;
							console.log("no try reconnect, another socket opened");
						}
					}


				}
				else {
					console.log("call tryconnet with a socket already opened");
				}
			}
			else {

				self.chatComponent.noInternetMessage();
				clearTimeout(self.timrecon);
				clearTimeout(self.timerPong);
				clearTimeout(self.timrecon);
				self.reloaddata = true;
				self.socketReOpened = true;
				self.retryconnect = true;

				self.timrecon = setTimeout(function () { self.tryOpenConn(self, self.chatComponent) }, 5000);
			}
		});
	}

	getQueueById(queueId: number): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/get/${queueId}`, { headers: httpHeaders })

			.pipe(
				map((response) => {

					var queue: QueueModel;

					queue = response['data'];
					if (queue) {

						return queue;

					}
					else {
						return null;
					}
				})


			);
	}
	public setViews(cant: number) {
		this.userViews = cant;
	}
	//onecall;
	private constructStatusModelResults() {
		let statusModel: Array<any>;
		this.statusModelResults = new Array();
		for (let i = 0; i < this.userViews; i++) {
			statusModel = new Array();
			statusModel.push(0);
			statusModel.push(false);
			statusModel.push();
			this.statusModelResults.push(statusModel);
		}
	}
	//onecall;
	private constructStatusModelInputs() {
		let statusModel: StatusInputModel;
		this.statusModelInputs = new Array();
		for (let i = 0; i < this.userViews; i++) {
			statusModel = { firstId: null, statusTime: null, chatUserId: null };

			this.statusModelInputs.push(statusModel);
		}
	}
	private constructQueueModelResults() {
		let queueModel: Array<any>;
		this.queuesModelResults = new Array();

	}
	//onecall;
	private constructQueueModelInputs() {
		let queueModel: QueueInputModel;
		this.queuesModelInputs = [];

	}
	//onecall;
	private setStatusResult(data: any) {
		let statusModel: Array<any>;
		this.statusModelResults = new Array();

		for (let i = 0; i < this.userViews; i++) {
			if (data[i] != null) {
				let d = data[i];


				statusModel = new Array();
				statusModel.push(this.statusModelInputs[i].chatUserId);
				statusModel.push(true);

				statusModel.push(d);

				this.statusModelResults.push(statusModel);
			}
			else {
				statusModel = new Array();
				statusModel.push(0);
				statusModel.push(false);
				statusModel.push(null);
				this.statusModelResults.push(statusModel);
			}
		}
	}


	public setMessageModelInputs(interactionId: number, lastMessageId: number, chatUserId: string, tileNum: number) {
		let messageInputModel: MessageInputModel;
		messageInputModel = { interactionId: interactionId, lastMessageId: lastMessageId, chatUserId: chatUserId };

		if (this.messageModelInputs == null || this.messageModelInputs == undefined)
			return null;
		this.messageModelInputs[tileNum - 1] = messageInputModel;
		let messageModel: Array<any> = new Array();
		messageModel.push(0);
		messageModel.push(false);
		messageModel.push();

		this.messageModelResults[tileNum - 1] = messageModel;
	}

	public setStatusModelInputs(firstId: number, statusTime: number, chatUserId: string, tileNum: number): any {
		let statusInputModel: StatusInputModel;
		statusInputModel = { firstId: firstId, statusTime: statusTime, chatUserId: chatUserId };

		if (this.statusModelInputs == null || this.statusModelInputs == undefined)
			return null;
		this.statusModelInputs[tileNum - 1] = statusInputModel;

		let statusModel: Array<any> = new Array();
		statusModel.push(0);
		statusModel.push(false);
		statusModel.push();

		this.statusModelResults[tileNum - 1] = statusModel;
		return "ok";
	}
	public setQueueModelInputs(queueId: number, typeQueue: number) {

		let sm: QueueInputModel = { queueId: queueId, typeQueue: typeQueue };

		if (this.queuesModelInputs === undefined)
			this.queuesModelInputs = [];
		if (this.queuesModelInputs[queueId] == undefined)
			this.queuesModelInputs[queueId] = sm;
	}
	public unSetQueueModelInputs(queueId: number, typeQueue: number) {


		this.queuesModelInputs[queueId] = null;
	}

	private constructMessageModelResults() {
		let messageModel: Array<any>;
		this.messageModelResults = new Array();
		for (let i = 0; i < this.userViews; i++) {
			messageModel = new Array();
			messageModel.push(0);
			messageModel.push(false);
			messageModel.push();
			this.messageModelResults.push(messageModel);
		}
	}
	//onecall;
	private constructMessageModelInputs() {
		let messageModel: MessageInputModel;
		this.messageModelInputs = new Array();
		for (let i = 0; i < this.userViews; i++) {
			messageModel = { interactionId: null, lastMessageId: null, chatUserId: null };

			this.messageModelInputs.push(messageModel);
		}
	}
	//onecall;
	private setQueueFinalClientModelLoaded(data: any) {
		this.queueFinalClientModelLoaded = data;
	}
	private setQueueModelResult(data: any) {
		this.queuesModelResults = data;

	}
	private setMessageResult(data: any) {
		let messageModel: Array<any>;
		this.messageModelResults = new Array();

		for (let i = 0; i < this.userViews; i++) {
			if (data[i] != null) {
				let d = data[i];

				messageModel = new Array();
				messageModel.push(this.messageModelInputs[i].chatUserId);
				messageModel.push(true);
				messageModel.push(d);

				this.messageModelResults.push(messageModel);
			}
			else {
				messageModel = new Array();
				messageModel.push(0);
				messageModel.push(false);
				messageModel.push(null);
				this.messageModelResults.push(messageModel);
			}
		}
	}

	private callChatServerHttp(params: any): Observable<any> {
		const httpHeaders = new HttpHeaders();

		return this.http.post(API_CHAT_URL + `/oneCall`, params, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {
						var data: any[];

						data = response['data'];

						return data;

					}
					else {
						sessionStorage.removeItem('userId');
						sessionStorage.removeItem('username');
						sessionStorage.removeItem('userRol');
						sessionStorage.removeItem('userViews');
						sessionStorage.removeItem('userDisplayName');
						sessionStorage.removeItem(environment.authTokenKey);
						console.log("lastErrorFromServer :" + response["data"]);
						let ret: Observable<any>;
						ret = Observable.create();
						this.router.navigateByUrl('/auth/login');
						return ret;
					}

				})

			);
	}

	//onecall;
	private callChatServer() {
		this.reloaddata = false;
		if (this.isInChatView) {

			let params: Array<any> = new Array();

			params.push(this.userId);
			params.push(this.imOnline);

			params.push(this.statusModelInputs);
			params.push(this.messageModelInputs);
			params.push(this.queuesModelInputs);
			if (this.statusTime == null)
				this.statusTime = "0";
			params.push(this.statusTime);


			this.callChatServerHttp(params).subscribe(data => {
				if (data) {

					this.setStatusResult(data[0]);//status
					this.setMessageResult(data[1]); //message

					this.setQueueFinalClientModelLoaded(data[2]); //loadedConversations
					this.setQueueModelResult(data[3]);
					if (data[4] != null)
						if (this.statusTime < data[4])
							this.statusTime = data[4];

				}

			});
		}





	}

	//onecall;

	getQueueFinalClientsByQueueId(queueId: number, typeQueue: number): QueueFinalClientModel[] {




		let sm: QueueInputModel = { queueId: queueId, typeQueue: typeQueue };
		if (this.queuesModelInputs == null || this.queuesModelInputs === undefined)
			return null;
		if (this.queuesModelInputs[queueId] == null) {
			this.queuesModelInputs[queueId] = sm;
			return null;
		}
		else {
			if (this.queuesModelResults != null && this.queuesModelResults[queueId] != null)

				return this.queuesModelResults[queueId][0];
			else
				return null;
		}

	}
	getLastStatTimeQueueFinalClientsByQueueId(queueId: number): string {
		if (this.queuesModelResults != null && this.queuesModelResults[queueId] != null) {


			return this.queuesModelResults[queueId][1];
		}
		else
			return null;


	}

	getNewsStatusByChatUserIdByStatusTime(chatUserId: string, statusTime: number, firstId: number, tileNum: number): StatusIdModel[] {

		let sm: StatusInputModel = { firstId: firstId, statusTime: statusTime, chatUserId: chatUserId };


		this.statusModelInputs[tileNum - 1] = sm;

		let statusModel = this.statusModelResults[tileNum - 1];
		if (statusModel != null && statusModel[0] != null && statusModel[0] != 0 && statusModel[0] == chatUserId && statusModel[1] == true) {

			return statusModel[2];


		}


		return null;
	}
	setUserId(userId: number) {
		this.userId = userId;
	}
	getLoadedInteractionsByUserId(): QueueFinalClientModel[] {

		if (this.userId != null) {

			return this.queueFinalClientModelLoaded;


		}

		return null;

	}

	getNewsChatFinalClientByChatUserId(chatUserId: string, lastMessageId: number, interactionId: number, tileNum: number): MessageChatModel[] {

		let sm: MessageInputModel = { interactionId: interactionId, lastMessageId: lastMessageId, chatUserId: chatUserId };

		this.messageModelInputs[tileNum - 1] = sm;

		let messageModel = this.messageModelResults[tileNum - 1];
		//console.log(messageModel);
		if (messageModel != null && messageModel[0] != null && messageModel[0] == chatUserId && messageModel[1] == true) {

			return messageModel[2];


		}


		return null;


	}

	unLoadConversation(interactionId: number, userId: string): Observable<any> {
		const httpHeaders = new HttpHeaders();

		return this.http.post(API_CHAT_URL + "/unloadconversation", { interactionId, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {


					response['data'];
					if (response['data']) {

						return "";
					}
					else {
						return null;
					}
				})

			);

	}

	sendTextMessage(chatuserid: string, textmessage: string) {
		const httpHeaders = new HttpHeaders();
		const type = "message";
		const contenttype = "";
		const caption = "";

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return response;
					else
						return false;
				})

			);


	}
	sendAudioMessage(chatuserid: string, base64: any, caption: string, type: string) {
		const httpHeaders = new HttpHeaders();

		const contenttype = "audio";

		const textmessage = base64;

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return response['data'];
				})

			);


	}

	sendDocumentMessage(chatuserid: string, base64: string, caption: string, cType: string, type: string) {
		const httpHeaders = new HttpHeaders();

		const contenttype = "document";

		const textmessage = base64;

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return response['data'];
				})

			);


	}
	sendImageMessage(chatuserid: string, base64: string, caption: string, cType: string, type: string) {
		const httpHeaders = new HttpHeaders();

		const contenttype = "image";

		const textmessage = base64;

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return response['data'];
				})

			);


	}
	sendVideoMessage(chatuserid: string, base64: string, caption: string, cType: string, type: string) {
		const httpHeaders = new HttpHeaders();

		const contenttype = "video";

		const textmessage = base64;

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return response['data'];
				})

			);


	}
	sendLocationMessage(chatuserid: string, base64: string, type: string) {
		const httpHeaders = new HttpHeaders();

		const contenttype = "location";
		const caption = "";
		const textmessage = base64;

		return this.http.post(API_CHAT_URL + "/sendanymessagebychatuserid", { chatuserid, textmessage, type, contenttype, caption }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return response['data'];
				})

			);


	}

	updateMeta(meta: MetaDataModel): Observable<MetaDataModel> {
		const httpHeaders = new HttpHeaders();
		let metaId = meta.id;
		let metaValue = meta.value;
		if (metaValue == null)
			metaValue = "";
		let userId = this.userId;
		return this.http.post(API_CHAT_URL + "/updatemetadatavaluebyid", { metaId, metaValue, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {

						return response['data'];
					}
					else
						return null;
				})

			);
	}
	createMeta(meta: MetaDataModel, chatUserId: string): Observable<MetaDataModel> {
		const httpHeaders = new HttpHeaders();

		let metaValue = meta.value;
		if (metaValue == null)
			metaValue = "";
		let metaKey = meta.key;
		let userId = this.userId;
		return this.http.post(API_CHAT_URL + "/createmeta", { chatUserId, metaValue, metaKey, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return response['data'];
					else
						return null;
				})

			);
	}
	deleteMeta(meta: MetaDataModel): Observable<boolean> {
		const httpHeaders = new HttpHeaders();
		let metaId = meta.id;

		let userId = this.userId;
		return this.http.post(API_CHAT_URL + "/deletemetadatavaluebyid", { metaId, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return false;
				})

			);
	}
	getDisplayName(finalClientId: string): Observable<string> {
		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getdisplayname/${finalClientId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					try {
						return JSON.parse(response['data']);
					}
					catch (e) {
						return response['data'];
					}


				})


			);
	}


	getMetaDataByFinalClientId(finalClientId: string): Observable<MetaDataModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getmetadatasbychatuserid/${finalClientId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var metas: MetaDataModel[];

					metas = response['data'];
					if (metas) {

						return metas;

					}
					else {
						return null;
					}
				})


			);
	}

	ngOnDestroy() {
		this.retryconnect = false;
		this.retryconnectOnClose = false;
		if (this.connectionSocket != null)
			this.connectionSocket.close();
		//this.chatService.setIsInChatView(false);
	}

	getCurrentChatStat(filtered: any[]): Observable<QueueStat[]> {
		let filteredString = "";
		if (filtered != null && filtered.length > 0 && !filtered.includes(0))
			filtered.forEach(element => {
				filteredString = filteredString + element + "-";
			});

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getCurrentChatStat`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {

						var series: QueueStat[];

						series = response['data'];

						if (series) {

							return series.map((item: QueueStat) => {
								let d: QueueStat;
								d = new QueueStat();
								d.id = Number(item.id);
								d.name = item.name;
								d.data = item.data.map((dat: Cant_Date) => {
									let s: Cant_Date;
									s = new Cant_Date();
									s.cant = Number(dat.cant);
									s.date = new Date(dat.date);
									return s;
								});
								return d
							});


						}
						else {
							return null;
						}
					}
					else {
						return null;
					}
				})


			);
	}
	getCompleteChatStat(fromD: Date, toD: Date, filtered: any[]): Observable<QueueStat[]> {

		const httpHeaders = new HttpHeaders();
		let from = "";
		let to = "";
		let filteredString = "";
		if (filtered != null && filtered.length > 0 && !filtered.includes(0))
			filtered.forEach(element => {
				filteredString = filteredString + element + "-";
			});
		if (fromD != null)
			from = fromD.toISOString();
		if (toD != null)
			to = toD.toISOString();

		return this.http.get(API_CHAT_URL + `/getCompleteChatStat?from=${from}&to=${to}&filter=${filteredString}`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {

						var series: QueueStat[];

						series = response['data'];

						if (series) {

							return series.map((item: QueueStat) => {
								let d: QueueStat;
								d = new QueueStat();
								d.id = Number(item.id);
								d.name = item.name;
								d.data = item.data.map((dat: Cant_Date) => {
									let s: Cant_Date;
									s = new Cant_Date();
									s.cant = Number(dat.cant);
									s.date = new Date(dat.date);
									return s;
								});
								return d
							});


						}
						else {
							return null;
						}
					}
					else {
						return null;
					}
				})

			);
	}

	getWaitTimeConversationStat(fromD: Date, toD: Date, filtered: any[]): Observable<QueueStatString[]> {

		const httpHeaders = new HttpHeaders();
		let from = "";
		let to = "";
		if (fromD != null)
			from = fromD.toISOString();
		if (toD != null)
			to = toD.toISOString();
		let filteredString = "";
		if (filtered != null && filtered.length > 0 && !filtered.includes(0))
			filtered.forEach(element => {
				filteredString = filteredString + element + "-";
			});

		return this.http.get(API_CHAT_URL + `/getWaitTimeConversationStat?from=${from}&to=${to}&filter=${filteredString}`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {

						var series: QueueStatString[];

						series = response['data'];

						if (series) {
							return series.map((item: QueueStatString) => {
								let d: QueueStatString;
								d = new QueueStatString();
								d.id = Number(item.id);
								d.name = item.name;
								d.data = item.data.map((dat: Cant_String) => {
									let s: Cant_String;
									s = new Cant_String();
									s.cant = Number(dat.cant);
									s.label = dat.label;
									return s;
								});
								return d
							});


						}
						else {
							return null;
						}
					}
					else {
						return null;
					}
				})


			);
	}
	getChatConversationStat(fromD: Date, toD: Date, filtered: any[]): Observable<QueueStat[]> {

		const httpHeaders = new HttpHeaders();
		let from = "";
		let to = "";
		if (fromD != null)
			from = fromD.toISOString();
		if (toD != null)
			to = toD.toISOString();
		let filteredString = "";
		if (filtered != null && filtered.length > 0 && !filtered.includes(0))
			filtered.forEach(element => {
				filteredString = filteredString + element + "-";
			});

		return this.http.get(API_CHAT_URL + `/getChatConversationStat?from=${from}&to=${to}&filter=${filteredString}`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success") {

						var series: QueueStat[];

						series = response['data'];

						if (series) {

							return series.map((item: QueueStat) => {
								let d: QueueStat;
								d = new QueueStat();
								d.id = Number(item.id);
								d.name = item.name;
								d.data = item.data.map((dat: Cant_Date) => {
									let s: Cant_Date;
									s = new Cant_Date();
									s.cant = Number(dat.cant);
									s.date = new Date(dat.date);
									return s;
								});
								return d
							});


						}
						else {
							return null;
						}
					}
					else {
						return null;
					}
				})


			);
	}


	updateNote(note: NoteModel): Observable<NoteModel> {
		const httpHeaders = new HttpHeaders();
		let noteId = note.id;
		let noteValue = note.value;
		if (noteValue == null)
			noteValue = "";
		let userId = this.userId;
		return this.http.post(API_CHAT_URL + "/updatenotevaluebyid", { noteId, noteValue, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return response['data'];
					else
						return null;
				})

			);
	}
	createNote(note: NoteModel, chatUserId: string): Observable<NoteModel> {
		const httpHeaders = new HttpHeaders();

		let noteValue = note.value;
		if (noteValue == null)
			noteValue = "";
		let noteKey = note.key;
		let userId = this.userId;
		return this.http.post(API_CHAT_URL + "/createnote", { chatUserId, noteValue, noteKey, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return response['data'];
					else
						return null;
				})

			);
	}
	deleteNote(note: NoteModel): Observable<boolean> {
		const httpHeaders = new HttpHeaders();
		let noteId = note.id;

		let userId = this.userId;

		return this.http.post(API_CHAT_URL + "/deletenotevaluebyid", { noteId, userId }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return true;
					else
						return false;
				})

			);
	}
	getNoteByFinalClientId(finalClientId: string): Observable<NoteModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getnotesbychatuserid/${finalClientId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var notes: NoteModel[];

					notes = response['data'];
					if (notes) {

						return notes;

					}
					else {
						return null;
					}
				})


			);
	}

	getChatFinalClientByChatUserId(chatUserId: string): Observable<MessageChatModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getbychatuserid/${chatUserId}?offset=0&count=100`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var chats: MessageChatModel[];

					chats = response['data'];
					if (chats) {

						return chats;

					}
					else {
						return null;
					}
				})


			);

	}
	getChatFinalClientByChatUserIdAll(chatUserId: string): Observable<MessageChatModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_CHAT_URL + `/getbychatuseridall/${chatUserId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var chats: MessageChatModel[];

					chats = response['data'];
					if (chats) {

						return chats;

					}
					else {
						return null;
					}
				})


			);

	}



	notifMessage(m: string): void {
		/*
		   body: string;
		icon: string;
		sound: string;
		data: any;
		tag: string;
		dir: NotificationDirection;
		lang: string;
		renotify: boolean;
		sticky: boolean;
		vibrate: Array<number>;
		noscreen: boolean;
		silent: boolean;*/
		const title = `${this.translate.instant('CHAT.CHROMENOTIFICATION.INDIVIDUALCHAT.TITLE')}  `;
		const options = new PushNotificationOptions();
		options.sound = "./assets/media/sounds/2.mp3";

		this.audio2.play();
		if (m == null)
			options.body = `${this.translate.instant('CHAT.CHROMENOTIFICATION.INDIVIDUALCHAT.NEWMESSAGESONCHAT')}  `;
		else
			options.body = m;
		options.sticky = true;
		const isShowing = sessionStorage.getItem("isShowingBrowserNotificacion");

		if (isShowing == "false") {
			this._pushNotificationService.create(title, options).subscribe((notif) => {
				if (notif.event.type === 'show') {
					//	console.log('onshow');
					sessionStorage.setItem("isShowingBrowserNotificacion", "true");

					setTimeout(() => {
						sessionStorage.setItem("isShowingBrowserNotificacion", "false");

					}, 5000);

				}
				if (notif.event.type === 'click') {
					//	console.log('click');

					notif.notification.close();
				}
				if (notif.event.type === 'close') {
					sessionStorage.setItem("isShowingBrowserNotificacion", "false");
					//	console.log('close');
				}
			},
				(err) => {
					sessionStorage.setItem("isShowingBrowserNotificacion", "false");
					//	console.log(err);
				});
		}
	}

	notifContact(cant: number, queueName: string): void {
		/*
		   body: string;
		icon: string;
		sound: string;
		data: any;
		tag: string;
		dir: NotificationDirection;
		lang: string;
		renotify: boolean;
		sticky: boolean;
		vibrate: Array<number>;
		noscreen: boolean;
		silent: boolean;*/
		const title = `${this.translate.instant('LEFTPANEL.CHROMENOTIFICATION.TITLE')}  `;
		const options = new PushNotificationOptions();
		options.sound = "./assets/media/sounds/1.mp3";

		if (cant == 1)
			options.body = `${this.translate.instant('LEFTPANEL.CHROMENOTIFICATION.NEWCONTACTONQUEUE')}  ` + "(" + queueName + ")";
		else if (cant > 1)
			options.body = `${this.translate.instant('LEFTPANEL.CHROMENOTIFICATION.NEWSCONTACTONQUEUEP1')}  ` + cant + `${this.translate.instant('LEFTPANEL.CHROMENOTIFICATION.NEWSCONTACTONQUEUEP2')}  ` + '(' + queueName + ")";
		options.sticky = true;
		const isShowing = sessionStorage.getItem("isShowingBrowserNotificacion");

		if (isShowing == "false") {

			this.audio.play();

			this._pushNotificationService.create(title, options).subscribe((notif) => {
				if (notif.event.type === 'show') {

					sessionStorage.setItem("isShowingBrowserNotificacion", "true");

					setTimeout(() => {
						sessionStorage.setItem("isShowingBrowserNotificacion", "false");

					}, 5000);
				}
				if (notif.event.type === 'click') {


					notif.notification.close();
				}
				if (notif.event.type === 'close') {
					sessionStorage.setItem("isShowingBrowserNotificacion", "false");

				}
			},
				(err) => {
					sessionStorage.setItem("isShowingBrowserNotificacion", "false");

				});
		}
	}
}



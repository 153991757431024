// Spain

export const locale = {
	lang: 'en',
	data: {
		AGENT: 'Agent',
		SUPERVISOR: 'Supervisor',
		USER: 'User',
		ROLE: 'Role',
		TRANSLATOR: {
			SELECT: 'Choose your language',
		},
		INTERNET: {
			ERRORMESSAGE: 'No Internet connection retrying connect to socket. All new data will be immediately loaded after reconnection.',
			RESUMEMESSAGE: 'Resume of socket connection successful. New data was reloaded, the notifications are available again. ',
			ERRORMESSAGEONSEND: 'No Internet Connection try again'
		},
		LOGIN: {
			TITLE:
			{
				INGRESAR: 'Login',
			},
			INPUT:
			{
				USUARIO: 'Enter your email',
				CONTRASENA: 'Enter your password',
			},
			VALIDATION:
			{
				USUARIO_REQUERIDO: 'You must enter your email',
				CONTRASENA_REQUERIDO: 'You must enter your password',
				LOGIN_INVALIDO: "The email or password are not correct, or the user has not been activated",
				LOGIN_ERROR: "Unknown error, please contact our support team"
			},
			LINK:
			{
				OLVIDO_CONTRASENA: 'I forgot my password'
			},
			BUTTON:
			{
				INGRESAR: 'Login'
			},
			TEXT:
			{
				CONTACTANOS: "CONTACT US"
			},
			ACTIONS: 'Actions',
			CREATE_POST: 'Create a new post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Applications',
			DASHBOARD: 'Dashboard'
		},
		MENU: {
			NEW: 'New',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create a new post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Applications',
			DASHBOARD: 'Dashboard',
			INDICATORS: 'Performance',
			CONTACTS: 'Contacts',
			CHAT: 'Chat',
			QUEUES: 'Queues',
			USERS: 'Users',
			PROFILE: 'Profile',
			CONFIGURATION: 'Configuration',


		},
		CONTACTS:
		{
			CONTACT:
			{
				SEARCHTEMPLATE: 'Buscar template',
				FILEURL: 'File External Url',
				FILEUPLOAD: 'Or Upload File',
				PREVIEWTITLE: 'Preview',
				SENDTEMPLATE: 'Send template',
				BODYPARAMETERS: 'Body parameters',
				TEMPLATESELECTION: 'Template selection',
				PARAMETERS: 'Parameters',
				TEMPLATES: 'Templates',
				EVENTMESSAGES:
				{
					SENDTEMPLATEOK: "The template was programmed to be sent correctly and will be sent in a few seconds.El template fue programado para su envío correctamente y será enviado en unos segundos.",
					SENDTEMPLATEERROR: "An error occurred while programming the template",
					CREATEOK: "The contact was successfully created ",
					SAVEOK: " The contact was successfully saved",
					ERRORDATERANGE: "Must select date range to search",
					ERRORMETA: "Must select an operator and a value to search by Metadata",
					RESETFILTERSOK: "Filters reset successful"
				},
				SAVE: "Save",
				RESETFILTERTOOLTIP: "Reset all filters and sorting",
				RESETFILTER: "Reset filters",

				EDITCONTACT: "Edit contact ",
				NEWCONTACT: "New contact",
				ALLMETADATA: "Meta-datas",
				ALLCHAT: "Chat",
				TITLE:
				{
					CUSTOMPROFILEDATA: "Other profile data "
				},
				BACK: "Back",
				CHANNEL: "Channel",
				ID: "Identifier",
				ENTEREL: "Enter the",
				NAME: "Name",
				EMAIL: "Email",
				COUNTRY: "Country",
				STATE: "State/Province",
				ADDRESS: "Address",
				ZIPCODE: "ZIP Code ",
				LINKTO: "External link ",
				DESCRIPTION: "Description",
				STATUS: "Status",
				LASTMESSAGE: "Last message",
				LASTMESSAGETIME: "Date",
				ERRORTRYAGAIN: "Error, please try again",
				BASICINFO: "Basic information ",
				CONTACTDATA: "Contact data ",
				TOOLTIP: {
					BACKTOCONTACTLIST: "Go back to the list of contacts ",
					GOTOLINK: "Go to link",
					SAVE: "Save basic information",
					CREATENEWCONTACT: "Create new contact"
				}
			},
			CONFIRMDIALOG:
			{
				CONFIRMSENDRSUMEHSMP1: 'Are you sure you want to send an HSM to restore the conversation? Remember that HSMs have a cost per message!',
				CONFIRMSENDRSUMEHSMP2: 'If you proceed, the HSM can take up to 20 seconds to be delivered and, once the client answers, the conversation will appear in the General queue',
				NO: 'NO',
				YES: 'YES'

			},
			EVENTMESSAGES:
			{
				SENDTOBOTOK: 'The contact was successfully sent to the bot',
				SENDTOBOTERROR: 'It was not possible to send the contact to the bot',
				SENDTOAGENTOK: 'The contact was successfully sent to the agent',
				SENDTOAGENTERROR: 'It was not possible to send the contact to the agent',
				SENDTOQUEUEERROR: 'It was not possible to excecute the action, the contact has been moved or taken by another agent',
				SENDTOQUEUEOK: 'The contact was successfully sent to the queue',
				SENDHSMRESUMEOK: 'The message to restore the conversation will be sent in a few seconds',
				SENDHSMRESUMEERROR: 'message = `It was no possible to send the message to restore the conversation',
				SENDTHSMRESUMEERRORNOTEMPLATE: 'There is no template assigned for the bot to restore the converation'

			},
			PAGE:
			{
				TITLE:
				{
					CONTACTSLIST: 'Contact list',
					CONTACTSLISTFILTER: 'Contact list - FILTER BY',
					FILTERBY: ' Filter by'
				}
			},
			TABLE:
			{
				FILTER:
				{
					TEXT: {
						LASTMESSAGETIME: 'Date:',
						SEARCHCONTACTBY: 'Search contact by:',
						STATUS: 'Status',
						INTERACTIONWHENTH: 'Interaction went through',
						FINISHAT: 'Finish at',
						METADATA: 'Metadata',
						SEARCH: 'Search',
						RESETFILTERS: 'Reset filters',

						VALUE: 'Value',
						KEY: "Key",
						EQUAL: "Equal",
						NOTEQUAL: "Not Equal",
						CONTAINS: "Contains",
						NOTCONTAINS: "Not Contains",
						GREATER: "Greater",
						LESS: "Less",
						LESSEQUAL: "Less Equal",
						GREATEREQUAL: "Greater Equal",
					},
					FIELD:
					{
						FROM: 'From',
						TO: 'To',
						ATLEAST3: '(at least 3 characters)',
						ID: 'Identifier',
						CURRENT: 'Current data',
						HISTORICAL: 'Historical data',

					}
				},
				NORECORDS: 'Ther is no record',
				PLEASEWAIT: 'Please wait...',
				HEADER:
				{
					FINALCLIENTID: 'Identifier',
					CHANNEL: 'Channel',
					DISPLAYNAME: 'Name',
					LASTMESSAGE: 'Last message',
					LASTMESSAGETIME: 'Last message time',
					LASTUPDATETIME: 'Update time',
					STATUS: 'Status',
					ACTIONS: 'Actions',
					PRESTATUS: 'Previous status',
					LASTINTQUEUE: 'Last intermediate queue'
				},
				LABELS:
				{


					LINKTODOCUMENT: 'File or media',
					LINKTOGOOGLE: 'Location',
					CONTACTSBYPAGE: 'Contacts by page'
				},
				STATUS:
				{
					BOT: 'BOT (NOT EXPIRED)',
					AGENT: 'AGENT',
					EXPIRED: 'EXPIRED',
					QUEUE: 'QUEUE',
					LRM: 'L.R.M'


				},
				ACTIONS:
				{
					TOOLTIP:
					{
						SHOWCONTACT: 'See contact',
						SENDRESUMEHSM: 'Send message to restore conversation',
						SENDTOAGENT: 'Send contact to agent mode',
						SENDTOBOT: 'Send contact to bot mode',
						SENDTOQUEUE: 'Send contact to queue'
					},
					SENDTOQUEUE:
					{
						SELECT:
						{
							SEARCH: 'Search (at least 3 characters)',
							NOMATCH: 'no match found'
						}
					}
				}
			}
		}
		,
		INDICATORS:
		{
			PAGE:
			{
				LABELS:
				{
					CHATCONVERSATIONNAME: 'Chat conversations',
					CHATCONVERSATIONDESC: 'Total number of contacts that got into each queue during the time period',
					CURRENTCHATNAME: 'Current Chats',
					CURRENTCHATDESC: 'Number of currently open chats',
					COMPLETECHATNAME: 'Complete Chats',
					COMPLETECHATDESC: 'Number of chats opened by agents and completed during the time period',
					WAITTIMECONVERSARIONNAME: 'Conversation Wait Time',
					WAITTIMECONVERSARIONDESC: 'Wait time in queues until being served by an agent'
				}
			},
			CHATCONVERSATION:
			{
				SCROLLBARX: "Select the time period to be observed from the time bar, or click and drag horizontally inside the graph to zoom in",
				NOINFO: "There is no information for that period",
				CSV:
				{
					HEADERS:
					{
						QUEUEID: "queue_id",
						QUEUENAME: "queue_name",
						COUNT: "quantity",
						DATE: "date"
					}
				},
				LABELS:
				{
					FROM: 'From:',
					TO: 'To:',
					FILTERQUEUES: 'Filter queues',
					ALLQUEUES: 'All queues'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data for the selected range',
						TODAYDATA: 'Today data',
						ONEWEEK: 'Since 1 week',
						ONEMONTH: 'Since 1 month',
						THREEMONTH: 'Since 3 months',
						SIXMONTH: 'Since 6 months',
						ONEYEAR: 'Since 1 year',
						BEGINYEAR: 'Since the beginning of the year',
						FULLDATA: 'Maximum period for which data exists'

					},
					TEXT:
					{
						TODAY: 'Day',
						ONEWEEK: '1w',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1y',
						BEGINYEAR: 'by',
						FULLDATA: 'MAX'
					},

					TOOLTIP: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHQUEUE: 'Search'
					},
					NOMATCHS: 'no match found'
				}
			},
			WAITTIMECONVERSATION:
			{
				SCROLLBARX: "Select the time period to be observed from the time bar, or click and drag horizontally inside the graph to zoom in",
				NOINFO: "There is no information for that period",
				CSV:
				{
					HEADERS:
					{
						QUEUEID: "queue_id",
						QUEUENAME: "queue_name",
						COUNT: "quantity",
						DATE: "date"
					}
				},
				LABELS:
				{
					FROM: 'From:',
					TO: 'To:',
					FILTERQUEUES: 'Filter queues',
					ALLQUEUES: 'All queues'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data for the selected range',
						TODAYDATA: 'Today data',
						ONEWEEK: 'Since 1 week',
						ONEMONTH: 'Since 1 month',
						THREEMONTH: 'Since 3 months',
						SIXMONTH: 'Since 6 months',
						ONEYEAR: 'Since 1 year',
						BEGINYEAR: 'Since the beginning of the year',
						FULLDATA: 'Maximum period for which data exists'
					},
					TEXT:
					{
						TODAY: 'Day',
						ONEWEEK: '1w',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1y',
						BEGINYEAR: 'by',
						FULLDATA: 'MAX'
					},

					TOOLTIP: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHQUEUE: 'Search'
					},
					NOMATCHS: 'no match found'
				}
			},
			CURRENTCHAT:
			{
				SCROLLBARX: "Select the time period to be observed from the time bar, or click and drag horizontally inside the graph to zoom in",
				NOINFO: "There is no information for that period",
				CSV:
				{
					HEADERS:
					{
						AGENTID: "agent_id",
						AGENTEMAIL: "agent_email",
						COUNT: "quantity",
						DATE: "date"
					}
				},
				LABELS:
				{
					FROM: 'From:',
					TO: 'To:',
					FILTERAGENTS: 'Filter agents',
					ALLAGENTS: 'All agents'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data for the selected range',
						TODAYDATA: 'Today data',
						ONEWEEK: 'Since 1 week',
						ONEMONTH: 'Since 1 month',
						THREEMONTH: 'Since 3 months',
						SIXMONTH: 'Since 6 months',
						ONEYEAR: 'Since 1 year',
						BEGINYEAR: 'Since the beginning of the year',
						FULLDATA: 'Maximum period for which data exists'
					},
					TEXT:
					{
						TODAY: 'Day',
						ONEWEEK: '1w',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1y',
						BEGINYEAR: 'by',
						FULLDATA: 'MAX'
					},

					TOOLTIP: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHAGENT: 'Search'
					},
					NOMATCHS: 'no match found'
				}
			},
			COMPLETECHAT:
			{
				SCROLLBARX: "Select the time period to be observed from the time bar, or click and drag horizontally inside the graph to zoom in",
				NOINFO: "There is no information for that period",
				CSV:
				{
					HEADERS:
					{
						AGENTID: "agent_id",
						AGENTEMAIL: "agent_email",
						COUNT: "quantity",
						DATE: "date"
					}
				},
				LABELS:
				{
					FROM: 'From:',
					TO: 'To:',
					FILTERAGENTS: 'Filter agents',
					ALLAGENTS: 'All agents'
				},
				BUTTONS:
				{
					TITLE: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data for the selected range',
						TODAYDATA: 'Today data',
						ONEWEEK: 'Since 1 week',
						ONEMONTH: 'Since 1 month',
						THREEMONTH: 'Since 3 months',
						SIXMONTH: 'Since 6 months',
						ONEYEAR: 'Since 1 year',
						BEGINYEAR: 'Since the beginning of the year',
						FULLDATA: 'Maximum period for which data exists'
					},
					TEXT:
					{
						TODAY: 'Day',
						ONEWEEK: '1w',
						ONEMONTH: '1m',
						THREEMONTH: '3m',
						SIXMONTH: '6m',
						ONEYEAR: '1y',
						BEGINYEAR: 'by',
						FULLDATA: 'MAX'
					},

					TOOLTIP: {
						SEARCH: 'Search',
						DOWNLOADDATA: 'Download data'
					}
				},
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHAGENT: 'Search'
					},
					NOMATCHS: 'no match found'
				}
			}
		},
		LEFTPANEL:
		{
			CHROMENOTIFICATION: {
				TITLE: 'Tyntec notification',
				NEWCONTACTONQUEUE: 'The queue has a new contact ',
				NEWSCONTACTONQUEUEP1: 'Queue has ',
				NEWSCONTACTONQUEUEP2: ' new contacts ',

			},
			EVENTMESSAGES:
			{
				SENDTOQUEUEERROR: "It was not possible to excecute the action, the contact has been moved or taken by another agent",
				SENDTOQUEUEOK: "The contact was successfully sent to the queue",
				LOADOK: "The contact chat has been loaded successfully",
				LOADERROR: "It was not possible to excecute the action, the contact has been moved or taken by another agent",
				CANTCHANGEVIEW: "It is not possible to change the view, you must close or move the currently loeaded conversations that would be hidden in the desired view"
			},
			FIELDS:
			{
				SELECT:
				{
					PLACEHOLDER:
					{
						SEARCHCONTACT: 'Search (at least 3 characters)',
						SEARCHQUEUE: 'Search (at least 3 characters)',


					},
					NOMATCH: 'no match found'
				}
			},
			BUTTONS:
			{
				TOOLTIP:
				{
					CLICTOLOAD: 'Click to load in the corresponding window chat',
					SENDTOQUEUE: 'Send to queue'
				}
			},
			LABELS:
			{
				USR: 'USR',
				VIEWS: 'Views',
				AVAIABLEQUEUES: 'Available queues'
			},
			TOOLTIPS:
			{
				QUEUESOTHER: "Queues and others",
			}
		},
		CHAT:
		{
			CHROMENOTIFICATION: {
				METADATA:
				{
					TITLE: 'Tyntec notification',
					NEWMESSAGESONCHAT: 'There are new messages in open conversations'
				},
				NOTE:
				{
					TITLE: 'Tyntec notification',
					NEWMESSAGESONCHAT: 'There are new messages in open conversations'
				},
				DOCUMENT:
				{
					TITLE: 'Tyntec notification',
					NEWMESSAGESONCHAT: 'There are new messages in open conversations'
				},
				IMAGE:
				{
					TITLE: 'Tyntec notification',
					NEWMESSAGESONCHAT: 'There are new messages in open conversations'
				},
				INDIVIDUALCHAT:
				{
					TITLE: 'Tyntec notification',
					NEWMESSAGESONCHAT: 'There are new messages in open conversations'
				}

			},
			CONFIRMDIALOG:
			{
				CONFIRMMESSAGE: 'Are you sure to delete this element?',
				NO: 'No',
				YES: 'Yes',
			},
			MESSAGECHAT:
			{
				USR: "USR",
				AGT: "AGT",
				BOT: "BOT",
				HSM: "HSM",
				LAT: "Lat:",
				LNG: "Lng:"
			},
			INDIVIDUALCHAT:
			{
				SELECTED: 'Selected',
				FORCETOAGENT: 'Send to agent mode',
				EVENTMESSAGES:
				{
					SENDTOQUEUEOK: 'The contact has been successfully sent to the queue',
					CLOSEOK: 'The chat has been successfully closed and the contact is back to bot mode',
					BACKOK: 'The contact has been successfully returned to the original queue'
				},
				DRAGDROPLABEL: 'You can drag a contact from the queue and drop it here',
				INPUTS:
				{
					TOOLTIPS:
					{
						WRITEHERE: 'Write here...',
						UNREADEDMESSAGES: 'Unreaded messages'
					}

				},
				BUTTON:
				{
					TOOLTIPS: {
						CALL: 'Call contact',
						CLOSECHAT: 'Close and quit agent mode',
						SHOWNOTES: 'See contact notes',
						SHOWMETA: 'See contact profile',
						BACKTOQUEUE: 'Back to original queue',
						SENDTOQUEUE: 'Send to queue',
						SENDIMAGE: 'Send image',
						MOREACTIONS: 'More Actions',
						SENDDOCUMENT: 'Send document',
						SENDVOICE: 'Send audio voice',
						SENDFORWARD: 'Forward messages',
						CANCELFORWARD: 'Cancel Forwarding',
						CANCELRECORDING: 'Cancel recording',
						SEND: 'Send',
						STOPRECORDING: 'Stop recording',
						PLEASETALKNOW: 'Please talk now ...'

					}
				},
				SELECT:
				{

					PLACEHOLDER:
					{
						SEARCH: 'Search'
					},
					NOMATCHS: 'no match found'


				}
			},
			SETFORWARDUSERS:
			{
				LABEL:
				{
					SELECTUSERS: 'Select contacts',
					FORWARD: 'Forward',
					MESSAGESTO: 'messages to',
					SETAGENTMODE: 'Set mode agent',
					SELECTQUEUE: 'Select queue',
					CONFIRM: 'Confirm',
					MESSAGEFORWARDERBY: 'Message forwarded by',
					STARTFORWARDING: 'Starting messages forward',
					ENDFORWARDING: 'All messages was forwarded'

				}
			},
			DOCUMENTS:
			{
				TITLE: 'Select and send a document',
				DRAGLABEL: 'Drag and drop the file or click to select it',
				SENDLABEL: 'Send',
				SENDLABELTOOLTIP: 'Send document'

			},
			IMAGES:
			{
				TITLE: 'Select and send an image',
				DRAGLABEL: 'Drag and drop the file or click to select it',
				SENDLABEL: 'Send',
				SENDLABELTOOLTIP: 'Send document',
				PLACEHOLDERCAPTION: 'Enter the caption'

			},
			NOTES:
			{
				EVENTMESSAGES:
				{
					DELETENOTEOK: "The note has been successfully erased.",
					DELETENOTEERROR: 'Error eliminating the note',
					SAVENOTEOK: 'The note has been successfully saved.',
					SUBJECTNOTBLANK: 'The subject field cannot be empty',
					SUBJECTNOTCONTAINRESERVED: 'The subject field cannot contain the text reserved_',
					THESUBJECT: 'The subject',
					EXIST: 'already exists',
					CREATENOTEOK: 'The note has been created successfully',
					CREATENOTEERROR: 'Error creating the note, the subject cannot be repeated'


				},
				LABEL:
				{
					CREATENOTE: 'Create note:',
					CONTACTNOTE: 'Contact notes',
					NONOTES: 'There are no notes'
				},
				FIELDS:
				{
					PLACEHOLDER: {
						VALUE: "Value",
						NOTE: "Note",
						SUBJECT: 'Subject'
					},
					SELECT:
					{
						PLACEHOLDER:
						{
							SEARCH: "Search by subject (at least 3 characters)"
						}
					},
					LABELS:
					{
						SUBJECT: 'Subject',
						NOTE: 'Note',
						USER: 'User',
						DATE: 'Date',
						ACTIONS: 'Actions'

					}
				},
				BUTTONS: {
					TOOLTIP: {
						CREATE: 'Create',
						DELETE: 'Delete',
						EDIT: 'Edit',
						SAVE: 'Save',
						CANCEL: 'Cancel'
					}
				}

			},
			METADATA:
			{
				EVENTMESSAGES:
				{
					DELETEMETAOK: "The meta-data was successfully deleted.",
					DELETEMETAERROR: 'Error deleting the meta-data',
					SAVEMETAOK: 'The meta-data was successfully saved.',
					KEYNOTBLANK: 'The field key cannot be empty',
					KEYNOTCONTAINRESERVED: 'The field key cannot contain the string reserved_',
					THEKEY: 'The key',
					EXIST: 'already exists',
					CREATEMETAOK: 'The meta-data was successfully created.',
					CREATEMETAERROR: 'Error creating the meta-data, the key cannot be repeated',
					CREATEPROFILEOK: 'The field was successfully created',


				},
				LABEL:
				{
					CREATEMETA: 'Create meta-data:',
					CREATEPROFILE: 'Create new profile field:',
					CONTACTMETA: 'Contact meta-data',
					NOMETA: 'There are no meta-data created yet',
					NOMETAPROFILE: 'There are no other profile fields created yet'
				},
				FIELDS:
				{
					PLACEHOLDER: {
						VALUE: "Value",
						FIELD: 'Type the field´s name',
						DATA: 'Type the data',
						KEY: 'Key'
					},
					SELECT:
					{
						PLACEHOLDER:
						{
							SEARCH: "Search by key (at least 3 characters)",
							SEARCHPROFILE: "Search by field (at least 3 characters)"
						}
					},
					LABELS:
					{
						KEY: 'Key',
						VALUE: 'Value',
						USER: 'User',
						DATE: 'Date',
						ACTIONS: 'Actions',
						DATA: 'Data',
						FIELD: 'Field',

					}
				},
				BUTTON: {
					ADD: 'ADD',
					TOOLTIP: {
						ADD: 'ADD',
						DELETE: 'Delete',
						EDIT: 'Edit',
						SAVE: 'Save',
						CANCEL: 'Cancel',
						FIELD: 'Field',
						DATA: 'Data',
					}
				}

			}
		},
		PROFILE:
		{
			TAB:
			{
				TITLE:
				{
					PERSONALQUEUE: 'Personal queue',
					PASSWORD: 'Password'
				}
			},
			EDIT:
			{
				FIELD:
				{
					LABEL:
					{
						OLDPASSWORD: 'Previous password',
						OLDPASSWORDREQ: 'Previous password is mandatory',
						NEWPASSWORD: 'New password',
						NEWPASSWORDREQ: 'New password is mandatory',

						NEWPASSWORD2: 'Enter the new password again',
						NEWPASSWORDREQ2: 'Confirming the new password is mandatory',

					},
					PLACEHOLDER:
					{
						QUEUECLOSED: 'Closed conversations',
						QUEUEGENERAL: 'General',
						QUEUEPERSONAL: 'Personal',
						QUEUEINTERMEDIATE: 'Intermediate',
						QUEUEEXPIRED: 'Expired conversations',
					}

				}
			}
		},
		AGENTS:
		{

			TITLE:
			{
				PROFILE: 'Profile'
			},
			EDIT:
			{
				ERRORTRYAGAIN: 'An error ocurred, please try again',
				FIELD:
				{
					PLACEHOLDER:
					{
						ID: 'Id',
						TYPEHERELEAVEBLANK: 'Type here or leave it blank'
					},
					LABEL:
					{
						ROL: 'Role:',
						USER: 'User:',
						EMAIL: 'Email:',
						ACTIVE: 'Active',
						FIRSTNAME: 'Name:',
						LASTNAME: 'Last Name:',
						CANRESTORE: 'Can restore conversation:',
						CANDOWNLOAD: 'Download conversation when it finishes:',
						TILESONCHAT: 'Tiles on chat',
						WELCOMEMESSAGE: 'Welcome message',
						GOODBYEMESSAGE: 'Goodbye message',
						LASTLOGIN: 'Last login:',
						CREATED: 'Created:',
						BY: 'by:',
						UPDATED: 'Updated:'

					}
				},
				BUTTON:
				{
					BACK: 'Back',
					SAVE: 'Save',
					TOOLTIP:
					{
						SAVE: 'Save data'
					}
				},
				TAB:
				{
					TITLE:
					{
						BASICINFO: 'Basic information',
					}
				},
				TOOLTIP:
				{
					SAVE: 'Save'
				}
			},
			EVENTMESSAGES:
			{
				EDITING: 'Editting',
				SENDRESETPASSWORDOK: 'The user password was reset and sent to the user email successfully',
				CREATEAGENTOK: 'The agent was successfully created.',
				SAVEAGENTOK: 'The agent was successfully saved.',
				PASSWORDNOTMATCH: 'ERROR: The new password and the new password confirmation do not match',
				WRONGOLDPASSWORD: 'ERROR: The previous password is not correct',
				PASSWORDCHANGEOK: 'The password was successfully changed'

			},
			TABLE:
			{
				PLEASEWAIT: 'Please wait...',
				NORECORDS: 'There are no records',
				LABELS:
				{
					AGENT: 'Agent',
					SUPERAGENT: 'Supervisor'
				},
				FILTER:
				{
					PLACEHOLDER: {
						SEARCHBYAGENT: 'Search for user name (at least 3 characters)'
					}
				},
				HEADER:
				{
					ID: 'Id',
					ROL: 'Role',
					USER: 'User',
					FIRSTNAME: 'Name',
					LASTNAME: 'Last name',
					LASTLOGIN: 'Last login',
					ACTIVE: 'Active',
					ACTIONS: 'Actions',
					RESETPASSWORD: 'Reset password'

				},
				ACTIONS:
				{
					TOOLTIP:
					{
						EDITAGENT: 'Edit agent',
						RESETPASSWORD: 'Reset password'
					}
				}
			},
			PAGE:
			{
				TITLE:
				{
					USERLIST: 'User list'
				}
				,
				BUTTON:
				{
					NEWAGENT: 'New user'
				},
				TOOLTIP:
				{
					CREATENEWAGENT: 'Create new user'
				}
			}
		},
		QUEUES:
		{
			TOOLTIPS:
			{
				CREATENEWQUEUE: 'Create new queue'
			},
			PLACEHOLDER:
			{
				SEARCHBYNAME: 'Search by name (at least 3 characters)'
			},
			QUEUE:
			{
				EVENTMESSAGES:
				{
					CREATEOK: 'The new queue was successfully created',
					SAVEOK: 'The queue was successfully saved.'
				},
				QUEUEDATA: 'Queue data',
				NAMEIS: "The name is",
				REQUIERED: "requiered",
				PLEASEENTEREL: "Please enter the",
				PLEASEENTERLA: "Please enter the",
				ENTERLA: "Enter the",
				ENTEREL: "Enter the",
				NAME: "Name",
				ID: "Id",
				CREATEDUSER: "Creted by",
				CREATEDTIMESTAMP: "Creation date",
				DESCRIPTION: "description",
				ACTIVE: "Active",
				YES: "YES",
				NO: "NO",
				WELCOMEMESSAGE: "Welcome message",
				TYPE: "Type",
				TYPEINIT: "Initial",
				TYPEINTER: "Intermediate",
				TYPEPERS: "Personal",
				SAVE: 'Save',
				RESET: 'Reset',
				BACK: 'Back',
				TOOLTIP: {
					SAVE: 'Save',
					BACKTOQUEUELIST: 'Go back to the list of queues',
					SAVECONTINUE: 'Save and continue',
					RESETECHANGES: 'Reset changes'
				},
				ERRORTRYAGAIN: 'An error has occurred, modify the data and try again',
				BASICINFO: 'Basic information',
				AGENTS: 'Agents',
				EDITQUEUE: 'Edit queue',
				AGENTTAB:
				{
					EVENTMESSAGES:
					{
						ASSIGNAGENTOK: 'The agents were successfully assigned'
					},
					AVAIABLETITLE: 'Available agents',
					SELECTEDTITLE: 'Assigned agents',

					SEARCHPLACEHOLDER: 'Search agent',
					TITLE: 'Assign agents to the queue'
				},
				REDIRECTS:
				{
					EVENTMESSAGES:
					{
						SAVEOK: 'Redirections were successfully saved.'

					},
					OPERATORS:
					{
						CONTAIN: 'Contains',
						NOCONTAIN: 'Does not contain',
						DISTINCT: 'Distinct',
						EQUAL: 'Equal',
						GREATER: 'Greater',
						GREATEREQUAL: 'Greater of equal',
						LESS: 'Less',
						LESSEQUAL: 'Less or equal',
					},
					LABELS:
					{
						NOREDIRECTS: 'There are no redirections based on profile data.',
						NEW: 'New',
						NOMATCHS: 'No match found'
					},
					PLACEHOLDER:
					{
						SELECTSEARCH: 'Search'
					},
					TOOLTIP:
					{
						CREATENEWREDIRECT: 'Create a new redirection based on profile data',
						DELETEREDIRECT: 'Delete redirection based on profile data'
					},
					REDIRECTS: 'Redirections',
					TIMEOUT:
					{
						TIMEOUTTITLE: 'Redirection based on wait time',
						DESTINATIONQUEUE: 'Destination',
						REDIRECTMESSAGE: 'Message when redirecting',
						TIMEOUT: 'Wait time',
						SECONDS: 'seconds',
						ACTIVE: 'Active'
					},
					WITHOUTAGENT:
					{
						WITHOUTAGENTTITLE: 'Redirection without agents online',
						DESTINATIONQUEUE: 'Destination',
						REDIRECTMESSAGE: 'Message when redirecting',
						ACTIVE: 'Active',
						ONLYNEWARRIVES: 'Only applicable to new contact arrivals'
					},
					META:
					{
						OPERATORREQUIERED: 'You must select an operator',
						OPERATOR: 'Operator',
						KEY: 'Profile field',
						VALUE: 'Value',
						METATITLE: 'Redirections based on profile data',
						DESTINATIONQUEUE: 'Destination',
						REDIRECTMESSAGE: 'Message when redirecting',
						DESTINATIONQUEUEREQUIERED: 'You must select the destination queue'
					}

				}
			},
			TABLE: {
				HEADER:
				{
					ACTIONS: 'Actions'
				},
				NEWQUEUE: "New queue",
				QUEUESLIST: "Queue list",
				ITEMSPERPAGE: "Queues per page",
				NEXTPAGE: "Next page",
				PREVPAGE: "Previous page",
				ID: 'Id',
				NORECORDS: 'No queues found',
				NAME: 'Name',
				PLEASEWAIT: 'Please wait...',
				DESCRIPTION: 'Description',
				ACTIVE: 'Active',
				ACTIONS: 'Actions',
				TOOLTIP: {
					EDITQUEUE: 'Edit queue',
					DELETEQUEUE: 'Delete queue'
				}
			}
		},
		AUTH: {
			BIENVENIDA: {
				BIENVENIDO: 'Welcome to Tyntec omnichannel multi-agent solution',
				DESCRIPCION: 'The ultimate platform for customer service through chat with chatbot automation support'

			},
			POLITICA: {
				LABEL: 'Usage policy',
				LINK: ''

			},

			CONTACTO: {
				LABEL: 'Contact',
				LINK: ''

			},

			LEGAL: {
				LABEL: 'Legal',
				LINK: ''

			},
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Send',
				NO_ACCOUNT: 'do not have an account yet?',
				SIGNUP_BUTTON: 'Register',
				FORGOT_BUTTON: 'Forgot my password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				BIENVENIDO: 'Welcome',
				TITLE: 'Create an account',
				BUTTON: 'Register',
				LOGIN: 'Login',
				EVENTMESSAGES:
				{
					MAXUSERREACHED: 'Error: The maximum number of simultaneous agents was reached'
				}
			},
			FORGOT: {
				TITLE: 'Forgot your password?',
				DESC: 'Enter your email for reseting your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'User'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions is mandatory',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',

				REQUIRED_FIELD: 'Mandatory field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		}

	}
};

// Angular
import { Component ,OnInit} from '@angular/core';
import {  Logout } from '../../../../core/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import {ChatService} from '../../../../core/management/_services/chat.service';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})

export class TopbarComponent implements OnInit{ 
		/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	userDisplayName:string;
	userRol:string;
	constructor(private store: Store<AppState>,private chatService:ChatService) {
	}
	ngOnInit(): void {
		this.userDisplayName=sessionStorage.getItem("userDisplayName");
		this.userRol=sessionStorage.getItem("userRol");
		
	}
	logout() {
		this.store.dispatch(new Logout());
		this.chatService.retryconnect=false;
		this.chatService.connectionSocket.close();
		sessionStorage.removeItem('userId');
		localStorage.clear();
		sessionStorage.clear();
		
	}
	
}

import { BaseModel } from '../../_base/crud';

export class Cant_Date  extends BaseModel {
	cant:number;
	date:Date;

	clear() {

		this.cant=undefined;
		this.date=undefined;
	
		
	
	}
	
	
}

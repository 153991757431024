// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
import { catchError, finalize, takeUntil, tap, map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { QueueModel } from '../_models/Queue.model';

import { AuthService } from '../../auth';
import { environment } from '../../../../environments/environment';
import { QueueMetaModel } from '../_models/queue-metadata.model';
import { QueueAgentUserModel } from '../_models/queue-agentuser.model';
import { QueueTimeOutModel } from '../_models/queue-timeout.model';
import { QueueWithOutAgentModel } from '../_models/queue-withoutagent.model';
import { QueueFinalClientModel } from '../_models/queue-finalclient.model';
import { Router } from '@angular/router';
import { EndPoint } from '../../auth/_models/enpoint.model';

const API_QUEUE_URL = environment.baseApiUrl + '/agent.php/queues';

// Real REST API
@Injectable()
export class QueuesService {
	queueMetas: QueueMetaModel[];
	private res: QueryResultsModel = new QueryResultsModel();
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
	queuesAll: QueueModel[];
	timeStamp: Date;
	firstTime: boolean;
	constructor(private http: HttpClient, private router: Router,
		private httpUtils: HttpUtilsService, private authService: AuthService) {

		this.timeStamp = new Date();
		this.firstTime = true;
	}

	// CREATE =>  POST: add a new Queue to the server
	createQueue(queue): Observable<QueueModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueModel>(API_QUEUE_URL + "/create", queue, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})
			);
	}

	setQueueMetas(val: QueueMetaModel[]) {
		this.queueMetas = val;
	}


	changeQueueConversationFromChat(interactionid: number, queuedestid: number, userid: string): Observable<any> {
		const httpHeaders = new HttpHeaders();
		//	console.log("change");

		return this.http.post(API_QUEUE_URL + "/changeQueueConversationFromChat", { interactionid, userid, queuedestid }, { headers: httpHeaders })
			.pipe(
				map((response) => {


					if (response['data']) {
						//				console.log(response["data"]);

						return response["data"];
					}
					else {
						return null;
					}
				})

			);

	}

	changeQueueConversationFromContact(interactionid: number, queuedestid: number, userid: string): Observable<any> {
		const httpHeaders = new HttpHeaders();


		return this.http.post(API_QUEUE_URL + "/changeQueueConversationFromContact", { interactionid, userid, queuedestid }, { headers: httpHeaders })
			.pipe(
				map((response) => {
					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}
				})

			);

	}
	unLoadConversation(interactionid: number, userid: string): Observable<any> {
		const httpHeaders = new HttpHeaders();

		return this.http.post(API_QUEUE_URL + "/unloadconversation", { interactionid, userid }, { headers: httpHeaders })
			.pipe(
				map((response) => {


					if (response['data']) {

						return response["data"];
					}
					else {
						return null;
					}
				})

			);

	}
	closeConversation(interactionid: number, userid: string, finalClientId: string): Observable<any> {



		const httpHeaders = new HttpHeaders();
		let closeConv = this.http.post(API_QUEUE_URL + "/closeconversation", { interactionid, userid }, { headers: httpHeaders })
			.pipe(
				map((response) => {


					if (response['data']) {


						return response["data"];
					}
					else {
						return null;
					}
				})

			);
		if (this.authService.currentUser.onCloseWH != undefined) {
			let adapterEndpoint: String = "";
			let targetServerJson: string = sessionStorage.getItem(environment.targetServerKey);
			if (targetServerJson != null && targetServerJson != undefined && targetServerJson != "") {
				const targetServer: EndPoint = JSON.parse(targetServerJson);
				adapterEndpoint = targetServer.adapterEndpoint;
			}
			let onCloseAdapter = this.http.post(adapterEndpoint + this.authService.currentUser.onCloseWH, { finalClientId, interactionid }, { headers: httpHeaders })
				.pipe(
					map((response2) => {

						//console.log(response2);

					}),
					catchError(error => {
						// simple logging, but you can do a lot more, see below
						console.error('An error occurred:', error);
						return null;
					})
				);
			return forkJoin([closeConv, onCloseAdapter]);

		}
		else
			return forkJoin([closeConv]);
	}

	getLoadedInteractionsByUserId(userId: string): Observable<any> {
		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/loadedconversationsbyuserid/${userId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var res: QueueFinalClientModel[];
					res = response['data'];
					if (res != null && res.length > 0)
						return res;
					else
						return false;

				})
				,
				catchError(error => {
					return null;

				})

			);

	}
	loadConversation(interactionid: number, userid: string, tilenum: number): Observable<any> {
		const httpHeaders = new HttpHeaders();

		return this.http.post(API_QUEUE_URL + "/loadconversation", { interactionid, userid, tilenum }, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})

			);

	}
	// READ
	getAllQueues(): Observable<QueueModel[]> {
		var n = new Date();
		var dif = n.getTime() - this.timeStamp.getTime();

		if (dif > 5000 || this.firstTime) {
			this.firstTime = false;
			this.timeStamp = n;
			const httpHeaders = new HttpHeaders();


			return this.http.get(API_QUEUE_URL + "", { headers: httpHeaders })
				.pipe(
					map((response) => {

						var queues: QueueModel[];

						queues = response['data'];
						this.queuesAll = queues;
						if (queues) {

							return queues;

						}
						else {
							return null;
						}
					})


				);
		}
		else
			return of(this.queuesAll);

	}

	getAllInterQueues(): Observable<QueueModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_QUEUE_URL + "/inter", { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queues: QueueModel[];

					queues = response['data'];
					if (queues) {

						return queues;

					}
					else {
						return null;
					}
				})

			);

	}
	getFilterQueuesStats(): Observable<QueueModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_QUEUE_URL + `/getfilterqueuesstats`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queues: QueueModel[];

					queues = response['data'];
					if (queues) {

						return queues;

					}
					else {
						return null;
					}
				})
			);

	}
	getAllInterQueuesByUserId(userId: string): Observable<QueueModel[]> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_QUEUE_URL + `/interbyuserid/${userId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queues: QueueModel[];

					queues = response['data'];
					if (queues) {


						return queues;

					}
					else {
						return null;
					}
				})

			);

	}
	getClosedQueue(): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();


		return this.http.get(API_QUEUE_URL + `/closed`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueModel[];
					queue = response['data'];
					if (queue != null && queue.length > 0)
						return queue[0];
					else
						return null;
				})


			);

	}
	getExpiredQueue(): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();


		return this.http.get(API_QUEUE_URL + `/expired`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueModel[];
					queue = response['data'];
					if (queue != null && queue.length > 0)
						return queue[0];
					else
						return null;
				})

			);

	}
	getGeneralQueue(): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_QUEUE_URL + "/general", { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueModel[];
					queue = response['data'];
					if (queue != null && queue.length > 0)
						return queue[0];
					else
						return null;

				})

			);

	}
	getIndividualQueue(userId: string): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();

		return this.http.get(API_QUEUE_URL + `/individual/${userId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueModel[];
					queue = response['data'];
					if (queue != null && queue.length > 0)
						return queue[0];
					else
						return null;

				})

			);

	}
	getWithOutAgentByQueueId(queueId: number): Observable<QueueWithOutAgentModel> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/getwithoutagent/${queueId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueWithOutAgentModel;
					queue = response['data'];
					if (queue) {
						return queue;

					}
					else {
						return null;
					}
				})


			);
	}
	getTimeOutByQueueId(queueId: number): Observable<QueueTimeOutModel> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/gettimeout/${queueId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueTimeOutModel;
					queue = response['data'];
					if (queue) {
						return queue;

					}
					else {
						return null;
					}
				})

			);
	}
	getQueueMetasByQueueId(queueId: number): Observable<QueueMetaModel[]> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/getmetas/${queueId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueMetaModel[];
					queue = response['data'];
					if (queue) {
						return queue;

					}
					else {
						return null;
					}
				})


			);
	}
	getQueueAgentUsersByQueueId(queueId: number): Observable<QueueAgentUserModel[]> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/getagentusers/${queueId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueAgentUserModel[];
					queue = response['data'];
					if (queue) {
						return queue;

					}
					else {
						return null;
					}
				})

			);
	}


	getQueueFinalClientInteractionNotEndedById(interactionId: number): Observable<QueueFinalClientModel> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/getfinalclientnotendedbyinteractionid/${interactionId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: QueueFinalClientModel;
					queue = response['data'];
					if (queue) {
						return queue;

					}
					else {
						return null;
					}
				})


			);
	}


	getLastStatTimeQueueFinalClientsByQueueId(queueId: number): Observable<string> {


		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/getlaststattimequeuefinalclientsbyqueueid/${queueId}`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var res: string;
					res = response['data'];
					if (res != null) {
						return res;

					}
					else {
						return "0";
					}
				})


			);

	}
	getQueueContactsLimit(): Observable<number> {
		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/maxContactsPerQueue`, { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queue: number;
					queue = response['data'];
					return queue;


				})


			);
	}
	getQueueFinalClientsByQueueId(queueId: number, typeQueue: number, userId: string): Observable<QueueFinalClientModel[]> {


		const httpHeaders = new HttpHeaders();
		if (typeQueue == null || typeQueue > 0) {
			return this.http.get(API_QUEUE_URL + `/getfinalclientsbyqueueid/${queueId}`, { headers: httpHeaders })
				.pipe(
					map((response) => {

						var queue: QueueFinalClientModel[];
						queue = response['data'];
						if (queue) {
							return queue;

						}
						else {
							return null;
						}
					})


				);
		}
		else {
			return this.http.get(API_QUEUE_URL + `/getfinalclientsbyqueueidbyuseridlimit100/${queueId}?userid=${userId}`, { headers: httpHeaders })
				.pipe(
					map((response) => {

						var queue: QueueFinalClientModel[];
						queue = response['data'];
						if (queue) {
							return queue;

						}
						else {
							return null;
						}
					})


				);
		}
	}
	getQueueById(queueId: number): Observable<QueueModel> {

		const httpHeaders = new HttpHeaders();
		return this.http.get(API_QUEUE_URL + `/get/${queueId}`, { headers: httpHeaders })

			.pipe(
				map((response) => {

					var queue: QueueModel;

					queue = response['data'];
					if (queue) {

						return queue;

					}
					else {
						return null;
					}
				})


			);
	}

	// Server should return filtered/sorted result
	findQueues(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		//const httpHeaders = this.httpUtils.getHTTPHeaders();
		//const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const httpHeaders = new HttpHeaders();

		let filter: string;
		if (queryParams.filter.name != null)
			filter = queryParams.filter.name.trim();
		else
			filter = "";

		let offset = queryParams.pageSize * queryParams.pageNumber;


		return this.http.get(API_QUEUE_URL + `/find?filter=${filter}&size=${queryParams.pageSize}&offset=${offset}`, { headers: httpHeaders })

			//return this.http.get(API_QUEUE_URL+"/find", { headers: httpHeaders })
			.pipe(
				map((response) => {

					var queues: QueueModel[];

					queues = response['data'][0];
					var total = response['data'][1];

					if (queues) {

						return new QueryResultsModel(queues, total, "");

					}
					else {
						return new QueryResultsModel([], 0, "");
					}
				})

			);


	}

	// UPDATE => PUT: update the Queue on the server
	updateQueue(queue: QueueModel): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueModel>(API_QUEUE_URL + "/update", queue, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})


			);
	}
	updateQueueWithOut(queue: QueueWithOutAgentModel): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueWithOutAgentModel>(API_QUEUE_URL + "/updatewithout", queue, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})


			);
	}
	updateQueueTimeOut(queue: QueueTimeOutModel): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueTimeOutModel>(API_QUEUE_URL + "/updatetimeout", queue, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"];
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})
			);
	}
	updateMetas(queues: QueueMetaModel[]): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueMetaModel[]>(API_QUEUE_URL + "/updatemetas", queues, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"] != null ? response["data"] : true;
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})


			);
	}
	updateAgentUsers(queues: QueueAgentUserModel[], queueid: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<QueueAgentUserModel[]>(API_QUEUE_URL + `/updateagentusers/${queueid}`, queues, { headers: httpHeaders })
			.pipe(
				map((response) => {

					if (response['status'] == "success")
						return response["data"] != null ? response["data"] : true;
					else {
						sessionStorage.setItem("lastErrorFromServer", response["data"]);
						return null;
					}

				})


			);
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForQueue(queues: QueueModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			queuesForUpdate: queues,
			newStatus: status
		};
		const url = API_QUEUE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}


	deleteQueues(ids: number[] = []): Observable<any> {
		const url = API_QUEUE_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}

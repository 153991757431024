// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// Auth
import { AuthDataContext } from '../../../../auth';
// 


@Injectable()
export class ApiService  {
	/**
	 * Service Constructore
	 */
	constructor() {}


}

export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Indicadores',//witty
					//title: 'Statistics',//tyntec
					root: true,
					alignment: 'left',
					page: '/statistics',
					translate: 'MENU.INDICATORS',
				},
				{
					title: 'Chat',
					root: true,
					alignment: 'left',
					active:true,
					page: '/chat',
					translate: 'MENU.CHAT'
				},
				{
					title: 'Contactos',//witty
					//title: 'Contacts',//tyntec
					root: true,
					alignment: 'left',
					active:true,
					page: '/management/contacts',
					translate: 'MENU.CONTACTS',
				},
				{
					
					title: 'Bandejas',//witty
					//title: 'Queues',//tyntec
					root: true,
					alignment: 'left',
					active:true,
					page: '/management/queues',
					translate: 'MENU.QUEUES',
					
				},
				{
					
					title: 'Usuarios',//witty
					//title: 'Users',//tyntec
					root: true,
					alignment: 'left',
					active:true,
					page: '/management/agents',
					translate: 'MENU.USERS',
					
					
				
				},
				{
					
					title: 'Perfil',//witty
					//title: 'Profile',//tyntec
					root: true,
					alignment: 'left',
					page: '/management/profile',
					translate: 'MENU.PROFILE',
					active:true
					
				},
				{
					
					title: 'Configuración',
					root: true,
					alignment: 'left',
					page: '/management/configuration',
					translate: 'MENU.CONFIGURATION',
					active:false
					
				},
				/*{
				title: 'Manage',
				root: true,
				alignment: 'left',
				toggle: 'click',
				translate: 'MENU.MANAGE',
				active:true,
				submenu: [
					{
					title: 'Form',
					translate: 'FORM.TITLE',
					alignment: 'left',
					page: '/management/manage/form',
					active:true
					},
							{
							title: 'Process',
							translate: 'PROCESS.TITLE',
							alignment: 'left',
							page: '/management/manage/process',
							active:true
							}
						]
				}*/
					
			]
		},
		aside: {
			self: {},
			items: [
			
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MatIconModule } from '@angular/material';

@NgModule({
	declarations: [],
	exports: [],
	imports: 
	[
		MatIconModule,
		CommonModule,
		CoreModule,
		PartialsModule,
		
	],
	providers: []
})
export class PagesModule {
	
}

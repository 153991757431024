/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
	/**
	 * Convert number to string and addinng '0' before
	 *
	 * @param value: number
	 */
	padNumber(value: number) {
		if (this.isNumber(value)) {
			return `0${value}`.slice(-2);
		} else {
			return '';
		}
	}

	/**
	 * Checking value type equals to Number
	 *
	 * @param value: any
	 */
	isNumber(value: any): boolean {
		return !isNaN(this.toInteger(value));
	}

	/**
	 * Covert value to number
	 *
	 * @param value: any
	 */
	toInteger(value: any): number {
		return parseInt(`${value}`, 10);
	}

	/**
	 * Convert date to string with 'MM/dd/yyyy' format
	 *
	 * @param date: Date
	 */
	dateFormat(date: Date): string {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		if (date) {
			return `${month}/${day}/${year}`;
		}

		return '';
	}
	dateSQLFormat(date: Date): string {
	
		
		if (date) {
			let month ="";
			if(date.getMonth() + 1<10)
				month="0"+(date.getMonth() + 1).toString();
			else
				month=(date.getMonth() + 1).toString();
			let day ="";
			if( date.getDate()<10)
				day="0"+( date.getDate()).toString();
			else
				day=( date.getDate()).toString();
	
			let year = date.getFullYear();
			
			let hours ="";
			if( date.getHours()<10)
			hours="0"+( date.getHours()).toString();
			else
			hours=( date.getHours()).toString();
				
			
			let minutes ="";
			if( date.getMinutes()<10)
			minutes="0"+( date.getMinutes()).toString();
			else
			minutes=( date.getHours()).toString();
	
	
			
			let seconds ="";
			if( date.getSeconds()<10)
			seconds="0"+( date.getSeconds()).toString();
			else
			seconds=( date.getSeconds()).toString();
	
			
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			}
	
		return '';
	}
	dateSQLFormatFromLocalToUTC(date: Date): string {
		
		if (date) {
		let month ="";
		if(date.getUTCMonth() + 1<10)
			month="0"+(date.getUTCMonth() + 1).toString();
		else
			month=(date.getUTCMonth() + 1).toString();
		let day ="";
		if( date.getUTCDate()<10)
			day="0"+( date.getUTCDate()).toString();
		else
			day=( date.getUTCDate()).toString();

		let year = date.getUTCFullYear();
		
		let hours ="";
		if( date.getUTCHours()<10)
		hours="0"+( date.getUTCHours()).toString();
		else
		hours=( date.getUTCHours()).toString();
			
		
		let minutes ="";
		if( date.getUTCMinutes()<10)
		minutes="0"+( date.getUTCMinutes()).toString();
		else
		minutes=( date.getUTCHours()).toString();


		
		let seconds ="";
		if( date.getUTCSeconds()<10)
		seconds="0"+( date.getUTCSeconds()).toString();
		else
		seconds=( date.getUTCSeconds()).toString();

		
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		}

		return '';
	}

	dateOnlySQLFormatFromLocalToUTC(date: Date): string {
		
		if (date) {
		let month ="";
		if(date.getUTCMonth() + 1<10)
			month="0"+(date.getUTCMonth() + 1).toString();
		else
			month=(date.getUTCMonth() + 1).toString();
		let day ="";
		if( date.getUTCDate()<10)
			day="0"+( date.getUTCDate()).toString();
		else
			day=( date.getUTCDate()).toString();

		let year = date.getUTCFullYear();
		

		
			return `${year}-${month}-${day}`;
		}

		return '';
	}

	/**
	 * Convert Date to string with custom format 'MM/dd/yyyy'
	 *
	 * @param date: any
	 */
	dateCustomFormat(date: any): string {
		let stringDate = '';
		if (date) {
			stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
   stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

			stringDate += date.year;
        }
  return stringDate;
	}

	/**
	 * Convert string to DateFormatter (For Reactive Forms Validators)
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFormatterFromString(dateInStr: string): any {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			return [
				{
					year: this.toInteger(dateParts[2]),
					month: this.toInteger(dateParts[0]),
					day: this.toInteger(dateParts[1])
				}
			];
		}

		const _date = new Date();
		return [
			{
				year: _date.getFullYear(),
				month: _date.getMonth() + 1,
				day: _date.getDay()
			}
		];
	}

	/**
	 * Convert string to Date
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFromString(dateInStr: string = ''): Date {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			const year = this.toInteger(dateParts[2]);
			const month = this.toInteger(dateParts[0]);
			const day = this.toInteger(dateParts[1]);
			let timeParts:any;
			 timeParts[0]="0";
			 timeParts[1]="0";
			 timeParts[2]="0";
			 if(dateParts[3]!==undefined)
				timeParts=dateParts[3].trim().split(':');

				const hours= this.toInteger(timeParts[0]);
				const minutes= this.toInteger(timeParts[1]);
				const seconds= this.toInteger(timeParts[2]);
			// tslint:disable-next-line:prefer-const
			let result = new Date();
			result.setDate(day);
			result.setMonth(month - 1);
			result.setFullYear(year);
			result.setHours(hours);
			result.setMinutes(minutes);
			result.setSeconds(seconds);
			return result;
		}

		return new Date();
	}
	getDateFromSQLFormatString(dateInStr: string = ''): Date {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('-');
			const year = this.toInteger(dateParts[0]);
			const month = this.toInteger(dateParts[1]);
			const day = this.toInteger(dateParts[2]);
			let timeParts:any=[];
			 timeParts[0]="0";
			 timeParts[1]="0";
			 timeParts[2]="0";

			 let hours=0;
			 let minutes=0;
			 let seconds=0;
			 if(dateParts[3]!==undefined)
			 {
				timeParts=dateParts[3].trim().split(':');
			 }
				 hours= this.toInteger(timeParts[0]);
				 minutes= this.toInteger(timeParts[1]);
				 seconds= this.toInteger(timeParts[2]);
			 
			
				
			// tslint:disable-next-line:prefer-const
			let result = new Date();
			result.setDate(day);
			result.setMonth(month - 1);
			result.setFullYear(year);
			result.setHours(hours);
			result.setMinutes(minutes);
			result.setSeconds(seconds);
			return result;
		}

		return new Date();
	}


	/**
	 * Convert Date to string with format 'MM/dd/yyyy'
	 * @param _date: Date?
	 */
	getDateStringFromDate(_date: Date = new Date()): string {
		const month = _date.getMonth() + 1;
		const year = _date.getFullYear();
		const date = _date.getDate();
		return `${month}/${date}/${year}`;
	}

}

import { BaseModel } from '../../_base/crud';

export class Cant_String  extends BaseModel {
	cant:number;
	label:String;

	clear() {

		this.cant=undefined;
		this.label=undefined;
	
		
	
	}
	
	
}

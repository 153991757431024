// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// RxJS
import { Observable, Subject, of } from 'rxjs';
import { catchError,finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { environment } from '../../../../../environments/environment.prod';
// Auth
import { AuthNoticeService, AuthService, Login,User } from '../../../../core/auth';
import {  TranslationService } from '../../../../core/_base/layout';

/**
 * ! Just example => Should be removed in development
 */


@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	env:any;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private translationService:TranslationService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.env=environment;
		sessionStorage.clear();
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
		var userLang = navigator.language  ;
		var lang="en";
		if(userLang.indexOf("es")!==-1)
		{
		this.translate.setDefaultLang('es');
		lang="es";
		}	
		else
		{this.translate.setDefaultLang('en');
		lang="en";
		}
		//detect nav language
		localStorage.setItem('language', lang);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = ``;
			this.authNoticeService.setNotice(initialNotice, 'info');
		}

		this.loginForm = this.fb.group({
			usuario: ['',Validators.compose([
				Validators.required
			])
			],
			password: ['',Validators.compose([
				Validators.required
			])
			]
		});
	}


	getFacebookLink()
	{
		return environment.facebooklink;
	}

	getLinkedinLink()
	{
		return environment.linkedinlink;
		
	}

	getWhatsappLink()
	{

		return environment.whatsapplink;
		
	}
	
	getTwitterLink()
	{
		
		return environment.twitterlink;
		
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			usuario: controls.usuario.value,
			password: controls.password.value
		};
		 
		this.auth
			.login(authData.usuario, authData.password)
			.pipe(
				tap(user => {
					if (user) {
						this.store.dispatch(new Login({authToken: user.accessToken}));
						sessionStorage.setItem('userId', user.id);
						sessionStorage.setItem('username', user.username);
						sessionStorage.setItem('userRol', user.rol);
						sessionStorage.setItem('userViews', user.views);
						sessionStorage.setItem('userDisplayName', user.firstName +" "+ user.lastName);
						this.auth.setNotif().subscribe(res => {
						});
			this.auth.getLanguage(user.id).subscribe(res => {
				if(res!=null && res!="")
				{
					
				this.translationService.setLanguage(res);
				localStorage.setItem('language', res);
				}
							this.router.navigateByUrl(this.returnUrl); // Main page
					
		
			});
		
					} else {
						let msg=sessionStorage.getItem('lastErrorFromServer');
						sessionStorage.removeItem('lastErrorFromServer');
						if(msg.includes("Invalid"))
						this.authNoticeService.setNotice(this.translate.instant('LOGIN.VALIDATION.LOGIN_INVALIDO'), 'danger');
						else if(msg.includes("maxAgentsOnline"))
						this.authNoticeService.setNotice(`${this.translate.instant('AUTH.LOGIN.EVENTMESSAGES.MAXUSERREACHED')}  `, 'danger');
						
					}
				}),
				catchError(this.handleError()),
			
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
				//	this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			this.authNoticeService.setNotice(this.translate.instant('LOGIN.VALIDATION.LOGIN_ERROR'), 'danger');
			
		  // TODO: send the error to remote logging infrastructure
		  console.error(error); // log to console instead
	   
		  // TODO: better job of transforming error for user consumption
	//	  this.log(`${operation} failed: ${error.message}`);
	   
		  // Let the app keep running by returning an empty result.
		  return  of(result as T);
		};
	}
	
}

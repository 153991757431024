// Angular
import { Injectable } from '@angular/core';
import { HttpEvent,HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import {  Logout } from '../../../../core/auth/_actions/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { EndPoint } from '../../../../core/auth/_models/enpoint.model';

//import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	

	
	constructor(private store: Store<AppState>) { }
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		
		const userToken = sessionStorage.getItem(environment.authTokenKey);
		const targetServerJson:string = sessionStorage.getItem(environment.targetServerKey);
		if(targetServerJson!=null && targetServerJson!=undefined && targetServerJson!="")
		{
		const targetServer:EndPoint=JSON.parse(targetServerJson);
		 request = request.clone({
		 	setHeaders: {
				//'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
				'token': `${userToken}`,
				 'Content-Type': `application/json; charset=utf-8`
		 	},
			 params: (request.params ? request.params : new HttpParams())
			 .set('target', targetServer.target)
			 .set('targetgroup', targetServer.targetGroup)
		 });
		}
		else
		{
			request = request.clone({
				setHeaders: {
				   //'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
				   'token': `${userToken}`,
					'Content-Type': `application/json; charset=utf-8`
				}
			});

		}
		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
					}
					
				}
			),
			
			catchError((error: HttpErrorResponse)=> {
				
				console.log("-------Error on request");
				console.log(error);
			
				
				return throwError(event);
			})
		);
	}
}

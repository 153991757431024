// Angular
import { Injectable } from '@angular/core';
// Tranlsation
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from '../../../auth';
import { environment } from '../../../../../environments/environment';
export interface Locale {
	lang: string;
	// tslint:disable-next-line:ban-types
	data: Object;
}

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	// Private properties
	private langIds: any = [];

	/**
	 * Service Constructor
	 *
	 * @param translate: TranslateService
	 */
	constructor(private translate: TranslateService, private authService:AuthService) {
		// add new langIds to the list
		this.translate.addLangs(['es']);
		this.translate.addLangs(['en']);
		var userLang;
		
		if( localStorage.getItem('language')!=null &&  localStorage.getItem('language')!="" )
		{
			
			this.translate.setDefaultLang( localStorage.getItem('language'));
			this.translate.use(this.translate.getDefaultLang());	
		}
		else
		{
		if(this.authService.currentUser==null)
			{

			
				userLang = navigator.language;
			   
		   if(userLang.indexOf("es")!==-1)
			   this.translate.setDefaultLang('es');
		   else
			   this.translate.setDefaultLang('en');
			   
			   this.translate.use(this.translate.getDefaultLang());
			}
			else
			{
				this.authService.getLanguage(this.authService.currentUser.id).subscribe(res => {
					if(res!=null && res!="")
						this.translate.setDefaultLang(res);
					else
					{
						userLang=localStorage.getItem('language');
						if(userLang==null || userLang=="")
						userLang = navigator.language;
			   
						if(userLang.indexOf("es")!==-1)
						this.translate.setDefaultLang('es');
						else
						this.translate.setDefaultLang('en');
				
					}
					this.translate.use(this.translate.getDefaultLang());
				});
			}
		}
			
	}

	/**
	 * Load Translation
	 *
	 * @param args: Locale[]
	 */
	loadTranslations(...args: Locale[]): void {
		const locales = [...args];

		locales.forEach(locale => {
			// use setTranslation() with the third argument set to true
			// to append translations instead of replacing them
			this.translate.setTranslation(locale.lang, locale.data, true);

			this.langIds.push(locale.lang);
		});

		// add new languages to the list
		this.translate.addLangs(this.langIds);
	}

	/**
	 * Setup language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		if (lang) {
			this.translate.use(this.translate.getDefaultLang());
			this.translate.use(lang);
			localStorage.setItem('language', lang);
			if(this.authService.currentUser==null)
			{
				var l="en";
				var userLang = navigator.language  
				if(userLang.indexOf("es")!==-1)
					{ 
						 l="es";
						this.translate.setDefaultLang('es');
				}
				else{ l="es";
				this.translate.setDefaultLang('en');
			}
				//detect nav language
				localStorage.setItem('language', l);
			}
			else
			{
				if(this.authService.currentUser!=null && this.authService.currentUser.id!=null && sessionStorage.getItem(environment.authTokenKey)!=null)
				{
					this.authService.setLanguage(this.authService.currentUser.id,lang).subscribe(res => {
				});
			}
			}
		}
	}

	/**
	 * Returns selected language
	 */
	getSelectedLanguage(): any {
		
		return localStorage.getItem('language') || this.translate.getDefaultLang();
	}
}

// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError,finalize, takeUntil, tap,map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { User } from '../../auth/_models/user.model';


import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

const API_AGENT_URL = environment.baseApiUrl+'/agent.php/agents';

// Real REST API
@Injectable()
export class AgentsService {
	 
	private res:QueryResultsModel= new QueryResultsModel();
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient, private router: Router,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Agent to the server
	createAgent(agent): Observable<User> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<User>(API_AGENT_URL+"/create", agent, { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				if (response['status']=="success")
					return response["data"];
				else
				{
					sessionStorage.setItem("lastErrorFromServer",response["data"]);
					return null;
				}
				
			})
			
		);
	}
	// READ
	
	getFilterAgentsStats(): Observable<User[]> {
		
		const httpHeaders = new HttpHeaders(); 
        
		return this.http.get(API_AGENT_URL+"/getfilteragentsstats", { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				var agents : User[];
				
				agents=response['data'];
				if(agents)
				{
					
					return agents;
					
				}
				else
				{
				  return null;
				}
			})
			
		);
	
	}
	getAllAgents(): Observable<User[]> {
		
		const httpHeaders = new HttpHeaders(); 
        
		return this.http.get(API_AGENT_URL+"", { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				var agents : User[];
				
				agents=response['data'];
				if(agents)
				{
					
					return agents;
					
				}
				else
				{
				  return null;
				}
			})
			
			
		);
	
	}
	getAgentById(agentId: number): Observable<User> {
		
		const httpHeaders = new HttpHeaders(); 
		return this.http.get(API_AGENT_URL + `/get/${agentId}`, { headers: httpHeaders })
		
		.pipe(
			map((response) => {
				
				var agent : User;
				
				agent=response['data'];
				if(agent)
				{
					
					return agent;
					
				}
				else
				{
				  return null;
				}
			})
			
			
		);
	}
	resetPassword(agentId: number): Observable<User> {
		
		const httpHeaders = new HttpHeaders(); 
		return this.http.get(API_AGENT_URL + `/resetpassword/${agentId}`, { headers: httpHeaders })
		
		.pipe(
			map((response) => {
				
				var agent : User;
				
				agent=response['data'];
				if(agent)
				{
					
					return agent;
					
				}
				else
				{
				  return null;
				}
			})
			
		);
	}

	// Server should return filtered/sorted result
	findAgents(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
			// Note: Add headers if needed (tokens/bearer)
			
			//const httpHeaders = this.httpUtils.getHTTPHeaders();
			//const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
			const httpHeaders = new HttpHeaders(); 
			let filter:string;
			
			if(queryParams.filter.username!=null)
			filter=queryParams.filter.username.trim();
		else
			filter="";
			
			let offset=queryParams.pageSize*queryParams.pageNumber;
	
			
			return this.http.get(API_AGENT_URL+`/find?filter=${filter}&size=${queryParams.pageSize}&offset=${offset}`, { headers: httpHeaders })
			.pipe(
				map((response) => {
					
					var agents : User[];
					
					agents=response['data'][0];
					var total=response['data'][1];
				
					if(agents)
					{
						
						return new QueryResultsModel(agents,total,"");
						
					}
					else
					{
						return new QueryResultsModel([],0,"");
					}
				})
				
				
			);
		
			
	}

	// UPDATE => PUT: update the Agent on the server
	
	changeViewsByUserId(cantviews:number,userid:string): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post(API_AGENT_URL+`/changeviews/${userid}?cantviews=${cantviews}`,  { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				if (response['status']=="success")
					return response["data"];
				else
				{
					sessionStorage.setItem("lastErrorFromServer",response["data"]);
					return null;
				}
				
			})
			
		);
	}
	
	updateAgent(agent: User): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<User>(API_AGENT_URL+"/update", agent, { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				if (response['status']=="success")
					return response["data"];
				else
				{
					sessionStorage.setItem("lastErrorFromServer",response["data"]);
					return null;
				}
				
			})
			
		);
	}
	
	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	
	// DELETE => delete the Agent from the server
	deleteAgent(agentId: number): Observable<User> {
		return null;
	}

	
}

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';

import * as Flashphoner from '@flashphoner/websdk';

const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
//const Browser = Flashphoner.Browser;
//const PRELOADER_URL = process.env.PUBLIC_URL + '/media/preloader.mp4';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CALL_STATUS } from '@flashphoner/websdk/src/constants';

@Component({
    selector: 'st-phone-call',
    templateUrl: './phoneCall.component.html',
    styleUrls: ['./phoneCall.component.scss']
})


export class PhoneCallComponent implements OnInit, AfterViewInit {
    @ViewChild("localVideo",{static:true}) localVideoElRef: ElementRef;
    @ViewChild("remoteVideo",{static:true}) remoteVideoElRef: ElementRef;
    selectedPhoneNumber:string;
    currentCall:any=null;
    start:number = 0;
    min:number = 0;
    secCount:string;
    minCount:string;
    showNumbers:boolean=false;
    tryTransfer:boolean=false;
    transferDestination:string;
    currentSatus:string;
    direction:string;
    sipSession:any;
    sipConnection:any;
    url:string;
    registerRequired:boolean = true;
    sipOptions:any;

    constructor(private authService:AuthService,private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        let self=this;
        this.authService.canMakeCalls.subscribe(result =>{
            if(result) {
                console.log(this.authService.sipData);
                this.setConnectionData();
                this.initSip();
                this.connect();
            }
        });
        this.authService.getSipData();

        this.authService.callTo.subscribe(result=> {
            self.selectedPhoneNumber="100";
            if(result!=null && result!="") {
                console.log("Calling to "+self.selectedPhoneNumber);
                this.currentSatus=CALL_STATUS.NEW;
                this.direction="OUT";
                this.tryTransfer=false;
                this.transferDestination=null;
                this.showNumbers=false;
                this.makeCall();
                // self.sipUA.start();
            }
        })
    }
    
    makeCall() {
        let self=this;
        var constraints = {
            audio: true,
            video: false
        };
        let localVideo = this.localVideoElRef.nativeElement;
        let remoteVideo = this.remoteVideoElRef.nativeElement;
        //this.currentSatus="RING";
        this.currentCall = this.sipSession.createCall({
                callee: this.selectedPhoneNumber,
                visibleName: this.sipOptions.login,
                localVideoDisplay: localVideo,
                remoteVideoDisplay: remoteVideo,
                constraints: constraints
        })
        .on(CALL_STATUS.RING, function(call){
            self.statusCallListener(CALL_STATUS.RING,call);
        })
        .on(CALL_STATUS.ESTABLISHED, function(call){
            self.start= 0;
            self.min= 0;
            self.startTimerCall();
            self.statusCallListener(CALL_STATUS.ESTABLISHED,call);
        })
        .on(CALL_STATUS.HOLD, function(call){
            self.statusCallListener(CALL_STATUS.HOLD,call);
        })
        .on(CALL_STATUS.FINISH, function(call){
            self.stopTimerCall();
            setTimeout(() => {
                self.currentSatus=null;
            }, 1000);
            self.statusCallListener(CALL_STATUS.FINISH,call);
        })
        .on(CALL_STATUS.FAILED, function(call){
            self.stopTimerCall();
            self.statusCallListener(CALL_STATUS.FAILED,call);
            setTimeout(() => {
                self.currentSatus=null;
            }, 1000);
        });
        console.log(this.currentCall);
        this.currentCall.call();
    }

    setConnectionData() {
        this.url = this.authService.sipData.webrtcUrl;
        this.sipOptions = {
            login: this.authService.sipData.sipLogin,
            authenticationName: this.authService.sipData.sipAuthName,
            password: this.authService.sipData.sipPassword,
            domain: this.authService.sipData.sipDomain,
            outboundProxy: this.authService.sipData.sipOutboundProxy,
            port: this.authService.sipData.sipPort,
            registerRequired: this.registerRequired
        };
    }

    ngAfterViewInit() { }

    stopTimerCall() { }

    refreshCounter() {
        this.start++;
        if (this.start > 59) {
            this.start = 0;
            this.min++;
            if (this.min < 10) {
                this.minCount="0" + this.min+"m";
            } else {
                this.minCount="" + this.min+"m";
            }
        } else {
            this.minCount="00m";
        } if (this.start < 10) {
            this.secCount="0" + this.start+"s";
        } else {
            this.secCount="" + this.start+"s";
        }
        this.cdr.detectChanges();
        let self=this;
        //if(this.currentSatus=="ESTABLISHED")
        setTimeout(function () {
            self.refreshCounter()
        }, 1000);
    }

    startTimerCall() {
        let self=this;
        setTimeout(function () {
            self.refreshCounter()
        }, 1000);
    };
    
    initSip() {
        console.log("initSip");
        try {
            Flashphoner.init({flashMediaProviderSwfLocation: '../../../../media-provider.swf'});
        } catch(e) {
            console.log("Your browser doesn't support WebRTC technology needed for this example");
            return;
        }
    }

    answer() {
        var constraints = {
            audio: true,
            video: false
        };
        let localDisplay = this.localVideoElRef.nativeElement;
        let remoteDisplay = this.remoteVideoElRef.nativeElement;
		this.currentCall.answer({
            localVideoDisplay: localDisplay,
            remoteVideoDisplay: remoteDisplay,
            receiveVideo: false,
            constraints: constraints
        });
    }

    cancelIncomming() {
        this.currentCall.hangup();
    }

    hangup() {
        this.currentCall.hangup();
    }

    clearNumber() {
        this.transferDestination=this.transferDestination.substring(0, this.transferDestination.length - 1);
        this.cdr.markForCheck();
    }

    addNumber(num) {
        if(this.transferDestination==null) {
            this.transferDestination="";
        }
        this.transferDestination=this.transferDestination+num;
        this.cdr.markForCheck();
    }

    transferConfirm() {
        if(this.transferDestination!=null && this.transferDestination.length>0) { 
            //this.currentCall.hold();
            this.tryTransfer=true;
            this.showNumbers=false;
            this.currentCall.holdForTransfer();
            let self=this;

            setTimeout(() => {
                self.currentCall.transfer(this.transferDestination);
                setTimeout(() => {
                    self.currentCall.hangup();
                    self.currentSatus=null;
                    self.selectedPhoneNumber=null;
                    self.tryTransfer=false;
                }, 2000);
            }, 2000);
        }
    }

    openNumbers() {
        this.transferDestination=null;
        if(!this.showNumbers) {
            this.showNumbers=true;
        } else {
            this.showNumbers=false;
        }
        this.cdr.markForCheck();
        ///this.currentCall.hangup();
    }

    connect() {
        /*if (Browser.isSafariWebRTC() && Flashphoner.getMediaProviders()[0] === "WebRTC") {
            Flashphoner.playFirstVideo(localDisplay, true);
            Flashphoner.playFirstVideo(remoteDisplay, false);
        }*/
        var connectionOptions = {
            urlServer: this.url,
            sipOptions: this.sipOptions,
            keepAlive: true
        };
        //create session
        let self=this;
        this.sipSession=Flashphoner.createSession(connectionOptions)
        .on(SESSION_STATUS.ESTABLISHED, (session, connection) =>{ 
            self.sipConnection=connection;            
            self.setStatus("#regStatus", SESSION_STATUS.ESTABLISHED);
            self.onConnected(session);
            if (!self.registerRequired) {
                this.disableOutgoing(false);
            }
        })
        .on(SESSION_STATUS.REGISTERED, (session) =>{
            self.setStatus("#regStatus", SESSION_STATUS.REGISTERED);
            self.onConnected(session);
            if (self.registerRequired) {
                self.disableOutgoing(false);
            }
        })
        .on(SESSION_STATUS.DISCONNECTED, () =>{
            self.setStatus("#regStatus", SESSION_STATUS.DISCONNECTED);
            self.onDisconnected();
        })
        .on(SESSION_STATUS.FAILED, () =>{
            self.setStatus("#regStatus", SESSION_STATUS.FAILED);
            self.onDisconnected();
        })
        .on(SESSION_STATUS.INCOMING_CALL, (call) =>{
            call
            .on(CALL_STATUS.RING, () =>{
                self.statusCallIncommingListener(CALL_STATUS.RING,call);
                self.setStatus("#callStatus", CALL_STATUS.RING);
            })
            .on(CALL_STATUS.ESTABLISHED, () =>{
                self.statusCallIncommingListener(CALL_STATUS.ESTABLISHED,call);
                self.setStatus("#callStatus", CALL_STATUS.ESTABLISHED);
                //  $("#holdBtn").prop('disabled',false);
                self.start= 0;
                self.min= 0;
                self.startTimerCall() ;
                self.enableMuteToggle(true);
            })
            .on(CALL_STATUS.HOLD, ()  =>{
                //  $("#holdBtn").prop('disabled',false);
            })
            .on(CALL_STATUS.FINISH, () =>{
                self.stopTimerCall() ;
                self.statusCallIncommingListener(CALL_STATUS.FINISH,call);
                self.setStatus("#callStatus", CALL_STATUS.FINISH);
                setTimeout(() => {
                    self.currentSatus=null;
                }, 1000);
            })
            .on(CALL_STATUS.FAILED, () =>{
                self.stopTimerCall() ;
                self.statusCallIncommingListener(CALL_STATUS.FAILED,call);
                self.setStatus("#callStatus", CALL_STATUS.FAILED);
                setTimeout(() => {
                    self.currentSatus=null;
                }, 1000);
            });
            self.onIncomingCall(call);
        });
    }
    
    onConnected(session) {
        /*  $("#connectBtn, #connectTokenBtn").text("Disconnect").off('click').click((){
            $(this).prop('disabled', true);
            if (this.currentCall) {
                this.showOutgoing();
                this.disableOutgoing(true);
                this.setStatus("#callStatus", "");
                this.currentCall.hangup();
            }
            session.disconnect();
        }).prop('disabled', false);*/
    }

    onDisconnected() {
        /*  $("#connectBtn").text("Connect").off('click').click((){
            if (this.validateForm("formConnection")) {
                this.disableConnectionFields("formConnection", true);
                $(this).prop('disabled', true);
                this.connect();
            }
        }).prop('disabled', false);

        $("#connectTokenBtn").text("Connect with token").off('click').click((){
            if ($("#authToken").val()) {
                this.disableConnectionFields("formTokenConnection", true);
                $(this).prop('disabled', true);
                this. connect($("#authToken").val());
            }
        }).prop('disabled', false); */
        this.disableConnectionFields("formConnection", false);
        this.disableOutgoing(true);
        this.showOutgoing();
        this.setStatus("#callStatus", "");
    }

    onHangupOutgoing() {
        /* $("#callBtn").text("Call").off('click').click((){
            if (this.filledInput($("#callee"))) {
                this.disableOutgoing(true);
                this.call();
            }
        }).prop('disabled', false);
        $('#callee').prop('disabled', false);
        $("#callFeatures").hide();
        $("#holdBtn").text("Hold");*/
        this.disableOutgoing(false);
        this.enableMuteToggle(false);
    }

    onIncomingCall(inCall) {
        this.currentCall = inCall;
        this.currentSatus="NEW";
        this.direction="IN";
        console.log("incomming");

        this.selectedPhoneNumber=inCall.caller();
        this.cdr.detectChanges();
        //this.currentSatus="RING";
        //this.showIncoming(inCall.caller());
        /*$("#answerBtn").off('click').click((){
        $(this).prop('disabled', true);
        var constraints = {
            audio: true,
            video: false
        };
        inCall.answer({
            localVideoDisplay: localDisplay,
            remoteVideoDisplay: remoteDisplay,
            receiveVideo: false,
            constraints: constraints
        });
        this.showAnswered();
        }).prop('disabled', false); */
        // $("#hangupBtn").off('click').click((){
        // $(this).prop('disabled', true);
        // $("#answerBtn").prop('disabled', true);
        // inCall.hangup();
        //}).prop('disabled', false);
    }

    onHangupIncoming() {
        this.showOutgoing();
        this.enableMuteToggle(false);
    }

    onAnswerOutgoing() {
        this.enableMuteToggle(true);
        // $("#callFeatures").show();
    }

    // Set connection and call status
    statusCallIncommingListener(status,call) {
        this.currentSatus=status;
        this.cdr.detectChanges();
        console.log(status);
    }

    statusCallListener(status,call) {
        this.currentSatus=status;
        this.cdr.detectChanges();
        console.log(status);
    }

    setStatus(selector, status) {
        //console.log(selector);
        //  console.log(status);
        /*  var statusField = $(selector);
        statusField.text(status).removeClass();
        if (status == "REGISTERED" || status == "ESTABLISHED") {
            statusField.attr("class","text-success");
        } else if (status == "DISCONNECTED" || status == "FINISH") {
            statusField.attr("class","text-muted");
        } else if (status == "FAILED") {
            statusField.attr("class","text-danger");
        } else if (status == "TRYING" || status == "RING") {
            statusField.attr("class","text-primary");
        } */
    }

    // Display view for incoming call
    showIncoming(callerName){
        /*   $("#outgoingCall").hide();
        $("#incomingCall").show();
        $("#incomingCallAlert").show().text("You have a new call from " + callerName);
        $("#answerBtn").show();
        */
    }

    // Display view for outgoing call
    showOutgoing(){
        /*  $("#incomingCall").hide();
        $("#incomingCallAlert").hide();
        $("#outgoingCall").show();
        $("#callFeatures").hide();*/
        this.onHangupOutgoing();
    }

    disableOutgoing(disable) {
        /* $('#callee').prop('disabled', disable);
        $("#callBtn").prop('disabled', disable);*/
    }

    // Display view for answered call
    showAnswered(){
    /* $("#answerBtn").hide();
    $("#callFeatures").show();
    $("#incomingCallAlert").hide().text("");*/
    }

    disableConnectionFields(formId, disable) {
        /* $('#' + formId + ' :text').each((){
        $(this).prop('disabled', disable);
        });
        $('#' + formId + ' :password').prop('disabled', disable);
        $('#' + formId + ' :checkbox').prop('disabled', disable);*/
    }

    validateForm(formId) {
        /*var valid = true;
        
        $('#' + formId + ' :text').each((){
            if(!this.filledInput($(this)) && valid) {
                valid = false;
            }
        });
        
        if(!this.filledInput($('#' + formId + ' :password')) && valid) {
            valid = false;
        }
        
        return valid;*/
    }

    filledInput(input) {
        /*	var valid = true;
        if (!input.val()) {
            valid = false;
            input.closest('.input-group').addClass("has-error");
        } else {
            input.closest('.input-group').removeClass("has-error");
        }
        return valid;*/
    }

    // Mute audio in the call
    mute() {
        if (this.currentCall) {
            this.currentCall.muteAudio();
        }
    }

    // Unmute audio in the call
    unmute() {
        if (this.currentCall) {
            this.currentCall.unmuteAudio();
        }
    }

    enableMuteToggle(enable) {
        /*  var $muteAudioToggle = $("#muteAudioToggle");
        
        if (enable) {
            $muteAudioToggle.removeAttr("disabled");
            $muteAudioToggle.trigger('change');
        }
        else {
            $muteAudioToggle.prop('checked',false).attr('disabled','disabled').trigger('change');
        }*/
    }
}
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef, OnDestroy, Inject, Renderer2 } from '@angular/core';

import * as Flashphoner from '@flashphoner/websdk';
import * as RoomAPI from '@flashphoner/websdk/src/room-module';

const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
const ROOM_EVENT = RoomAPI.events;
const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
//const Browser = Flashphoner.Browser;
//const PRELOADER_URL = process.env.PUBLIC_URL + '/media/preloader.mp4';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CALL_STATUS } from '@flashphoner/websdk/src/constants';
import {v4 as uuidv4} from 'uuid';
import { environment } from '../../../../../environments/environment';
import { DOCUMENT } from '@angular/common';

const inviteWEBRTCUrl = environment.inviteWEBRTCUrl;

@Component({
    selector: 'st-phone-web-call',
    templateUrl: './phoneWEBCall.component.html',
    styleUrls: ['./phoneWEBCall.component.scss']
})

export class PhoneCallWEBComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("localVideo",{static:true}) localVideoElRef: ElementRef;
    @ViewChild("remoteVideo",{static:true}) remoteVideoElRef: ElementRef;

    selectedPhoneNumber:string;
    currentCall:any=null;
    start:number = 0;
    min:number = 0;
    secCount:string;
    maxparticipants:number=1;
    showingScreen:boolean=true;
    showingVideo:boolean=true;
    showingVol:boolean=true;
    showingMic:boolean=true;
    fullScreenUp:boolean=false;
    fullScreenDwn:boolean=false;
    minCount:string;
    inviteAddress:string;
    localDisplay:any;
    remoteDisplay:any;
    stream:any;
    sharing:boolean=false;
    zoom:number=220; //Asumo que es el width base (Estaba en 190)
    numStream:number=0;
    mousepos:any;
    currentSatus:string;
    webRTCConnection:any;
    currentRoom:any;
    currentRoomName:string;
    url:string;
    showingNEW:boolean=false;
    closeVideoCall:boolean=false;
    showingESTABLISHED:boolean=false;
    
    constructor(private authService:AuthService,private cdr: ChangeDetectorRef) {
    }

    ngOnDestroy(): void {
        console.log("remove");
    }

    showScreen(): void {
        if(this.showingScreen) {
            this.showingScreen = false;
        } else {
            this.showingScreen = true;
        }
    }
//NUEVO
    showVideo(): void {
        if(this.showingVideo) {
            this.showingVideo = false;
        } else {
            this.showingVideo = true;
        }
    }
//NUEVO
    showVol(): void {
        if(this.showingVol) {
            this.showingVol = false;
        } else {
            this.showingVol = true;
        }
    }
//NUEVO
    showMic(): void {
        if(this.showingMic) {
            this.showingMic = false;
        } else {
            this.showingMic = true;
        }
    }
//NUEVO
    showTitleStates() {
        if (this.currentSatus= "ESTABLISHED") {
            this.showingESTABLISHED = true;
        } else if (this.currentSatus= "NEW") {
            this.showingNEW = true;
        } else {
            this.showingESTABLISHED = false;
            this.showingNEW = false;
        }
        console.log("showingNEW:" + this.showingNEW);
        console.log("showingESTABLISHED:" + this.showingESTABLISHED);
        console.log("currentSatus:" + this.currentSatus);
    }
//Editado
    zoomup() {
        if(this.zoom<620) {
            this.zoom=this.zoom+200;
        } 
        if(this.remoteDisplay.children[0]) {
            this.remoteDisplay.children[0].style.width=this.zoom+"px";
        }
        console.log("zoom:" + this.zoom);
        console.log("remoteDisplay:" + this.remoteDisplay);
    }
//Editado
    zoomdwn() {
        if(this.zoom>220) {
            this.zoom=this.zoom-200;
        }
        if(this.remoteDisplay.children[0]) {
            this.remoteDisplay.children[0].style.width=this.zoom+"px";
        }
        // console.log("zoom:" + this.zoom);
        // console.log("remoteDisplay:" + this.remoteDisplay);
    }
//NUEVO
    fullscreenup() {
        if(this.fullScreenUp){
            this.zoom = 100;
            if(this.remoteDisplay.children[0]) {
                this.remoteDisplay.children[0].style.width=this.zoom+"vw";
            }
            // console.log("zoom:" + this.zoom);
            // console.log("remoteDisplay:" + this.remoteDisplay);
        }
        // console.log("fullScreen:" + this.fullScreenUp);
    }
//NUEVO
    fullscreendwn() {
        this.fullScreenUp = false;
        this.zoom = 220;
        if(this.remoteDisplay.children[0]) {
            this.remoteDisplay.children[0].style.width=this.zoom+"px";
        }
        // console.log("zoom:" + this.zoom);
        // console.log("remoteDisplay:" + this.remoteDisplay);
    }

    ngOnInit() {
        let self=this;
        this.authService.canMakeCalls.subscribe( result =>{
            if(result) {
                console.log(this.authService.sipData);
                this.setConnectionData();
                this.initSip();
                console.log("Can make calls");
                // this.connect();
            }
        });
        this.authService.getSipData();

        this.authService.callWEBTo.subscribe(result=> {
            console.log("subscribe callwebto");
            self.selectedPhoneNumber=result;
            if(result!=null && result!="") {
                console.log("Calling to "+self.selectedPhoneNumber);
                self.currentSatus=CALL_STATUS.NEW;
                this.cdr.markForCheck();
                this.makeCall();
                // self.sipUA.start();
            }
        })
    }

    getRoomName() {
        //return "room-e3970ece-16d2-4196-9961-82b403df887b";
        return  "room-"+ uuidv4();
    }
    
    share() {
        if(this.sharing) {
            this.sharing=false;
            this.publishLocalMedia(this.currentRoom);
        } else {
            this.sharing=true;
            this.shareLocalMedia();
        }
    }
    
    makeCall() {
        this.zoom=100;
        this.sharing=false;
        let self=this;
        this.webRTCConnection = RoomAPI.connect({urlServer: this.url, username: this.authService.currentUser.username})
        .on(SESSION_STATUS.FAILED, function(session){})
        .on(SESSION_STATUS.DISCONNECTED, function(session) {
            console.log("disconect");
            self.setStatus( null);
        })
        .on(SESSION_STATUS.ESTABLISHED, function(session) {
            self.currentRoomName= self.getRoomName();
            self.webRTCConnection.join({name: self.currentRoomName})
            .on(ROOM_EVENT.STATE, function(room){
                self.currentRoom=room;
                console.log("Joined");
                self.authService.setCallWEBMessageTo(inviteWEBRTCUrl+"?roomName="+self.currentRoomName,self.selectedPhoneNumber);
                self.startTimerCall();
                self.setStatus("ESTABLISHED");
                self.publishLocalMedia(room);
                let participants = room.getParticipants();
                if (participants.length > 0) {
                    self.playParticipantsStream(participants[0]);
                }})
            .on(ROOM_EVENT.JOINED, function(participant){
                console.log("participant joined");
                console.log(participant);
            })
            .on(ROOM_EVENT.LEFT, function(participant){
                    //remove participant
                    console.log("LEFT");
                    self.setStatus( "LEFT");
                    setTimeout(() => {self.currentSatus=null;}, 1000);
            })
            .on(ROOM_EVENT.PUBLISHED, function(participant){
                    console.log("particiopant published")
                    console.log(participant);
                    self.playParticipantsStream(participant);
            })
            .on(ROOM_EVENT.FAILED, function(room, info){
                    self.stopTimerCall();
                    self.setStatus( "FAILED");
                    setTimeout(() => {self.currentSatus=null;}, 1000);
            })
            .on(ROOM_EVENT.MESSAGE, function(message){});
        });
    }

    setConnectionData() {
        this.url = this.authService.sipData.webrtcUrl;
    }

    shareLocalMedia() {
        let self=this;
        var constraints = {
            video: {
                width: 640,
                height: 480,
                frameRate: 30,
                withoutExtension: true,
                type:"screen"
            },
            audio: true
        };
        //this.localDisplay = this.localVideoElRef.nativeElement;
        if(this.stream) {
            this.stream.stop();
        }
        this.currentRoom.publish({
            display: this.localDisplay,
            constraints: constraints,
            name: "screenShare",
            cacheLocalResources: false})
        .on(STREAM_STATUS.FAILED, function (stream) {
            self.publishLocalMedia(self.currentRoom);
        })
        .on(STREAM_STATUS.STOPPED, function (stream) {
            console.log("STOPPED screen");
        })
        .on(STREAM_STATUS.PUBLISHING, function (stream) {
            self.stream=stream;
            console.log("publish screen");
            self.remoteDisplay.style.height=this.zoom+"px";
            /*User can stop sharing screen capture using Chrome "stop" button.
            * Catch onended video track event and stop publishing. */
            self.localDisplay.children[0].srcObject.getVideoTracks()[0].onended = function (e) {
                self.publishLocalMedia(self.currentRoom);
            };
            //this.localDisplay.addEventListener('resize', function(event){
            //resizeVideo(event.target);
            //});
            //onStartSharing(stream);
        })
        .on(STREAM_STATUS.UNPUBLISHED, function(stream) {
            console.log("end publish screen");
            //onStopSharing();
        });
    }
    
    playParticipantsStream(participant) {
        console.log(participant.name());
        if (this.authService.currentUser.username!= participant.name()) {
            if(participant.getStreams()[0]) {
                this.remoteDisplay.style.height="";
                this.remoteDisplay.innerHTML="";
                participant.getStreams()[this.numStream].play(this.remoteDisplay)
                .on(STREAM_STATUS.PLAYING, function (playingStream) {
                    console.log("play remote");
                    //document.getElementById(playingStream.id()).addEventListener('resize', function (event) {
                    // resizeVideo(event.target);
                    //});
                })
                .on(STREAM_STATUS.STOPPED, function (playingStream) {
                    console.log("play remote stoped");
                    //document.getElementById(playingStream.id()).addEventListener('resize', function (event) {
                    // resizeVideo(event.target);
                    //});
                })
                .on(STREAM_STATUS.UNPUBLISHED, function (playingStream) {
                    console.log("play remote unpublished");
                    //document.getElementById(playingStream.id()).addEventListener('resize', function (event) {
                    // resizeVideo(event.target);
                    //});
                });
                if(this.numStream==0) {
                    this.numStream=1;
                } else {
                    this.numStream=0;
                }
            } else {
                this.showingScreen=false;
            }
        }
    }

    publishLocalMedia(room) {
        this.sharing=false;
        this.cdr.markForCheck();
        let self=this;
        var constraints = {audio: true, video: true};
        this.localDisplay = this.localVideoElRef.nativeElement;
        if(this.stream) {
            this.stream.stop();
        }
        room.publish({
            display:this.localDisplay,
            constraints: constraints,
            record: false,
            receiveVideo: false,
            receiveAudio: false
        })
        .on(STREAM_STATUS.FAILED, function (stream) {})
        .on(STREAM_STATUS.PUBLISHING, function (stream) {
            console.log("publishing cam");
            self.stream=stream;
        })
        .on(STREAM_STATUS.UNPUBLISHED, function(stream) {});
    }

    afterScriptAdded() {
        const params= {
            width: '350px',
            height: '420px',
        };
        if (typeof (window['functionFromExternalScript']) === 'function') {
            window['functionFromExternalScript'](params);
        }
    }

    ngAfterViewInit() {
        this.remoteDisplay = this.remoteVideoElRef.nativeElement;
        this.remoteDisplay.style.height=this.zoom+"px";
    }

    stopTimerCall() { }

    refreshCounter() {
        this.start++;
        if (this.start > 59) {
            this.start = 0;
            this.min++;
            if (this.min < 10) {
                this.minCount="0" + this.min+"m";
            } 
            else {
                this.minCount="" + this.min+"m";
            }
        } else {
            this.minCount="00m";
        } if (this.start < 10) {
            this.secCount="0" + this.start+"s";
        } else {
            this.secCount="" + this.start+"s";
        }
        this.cdr.detectChanges();
        let self=this;
        //if(this.currentSatus=="ESTABLISHED")
        setTimeout(function () {self.refreshCounter()}, 1000);
    }

    startTimerCall() {
        let self=this;
        setTimeout(function () {
            self.refreshCounter()
        }, 1000);
    }
    
    initSip() {
        console.log("initSip");
        try {
            Flashphoner.init({flashMediaProviderSwfLocation: '../../../../media-provider.swf'});
        } catch(e) {
            console.log("Your browser doesn't support WebRTC technology needed for this example");
            return;
        }
    }
    
    finalizar() {
        console.log("Finalizando");
        this.currentRoom.leave();
        this.stream.stop();
        Flashphoner.releaseLocalMedia(this.localDisplay);
        console.log("Finalizando2");
        this.webRTCConnection.disconnect();
        this.closeVideoCall = true;
    }

    setStatus(status) {
        console.log(status);
        this.currentSatus=status;
        this.cdr.detectChanges();
    }
}
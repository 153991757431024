import { BaseModel } from '../../_base/crud';
import { QueueMetaModel } from './queue-metadata.model';
//import { QueueRemarkModel } from './queue-remark.model';

export class QueueModel extends BaseModel {
	id: number;
	botId: number;
	active: number;
	createdUserId: number;
	createdTimestamp: Date;
	description: string;
	name: string;
	type:number;
	welcomeMessage:String;

	metadataActive:number;
	timeoutActive:number;
	timeoutTime:number;
	timeoutActionDestinationQueueId:Number;
	timeoutMessage:String;
	cant:number;
	noAgentActive:number;
	noAgentMessage:String;
	noAgentActionDestinationQueueId:Number;
	_meta: QueueMetaModel[];
	//_remarks: QueueRemarkModel[];

	clear() {
		this.id=0;
		this.welcomeMessage='';
		this.botId = 0;
		this.active = 1;
		this.createdUserId = Number(sessionStorage.getItem('userId'));
		this.createdTimestamp = new Date();
		this.description = '';
		this.name = '';
		this.type=3;
		this.cant=0;
		this.metadataActive=0;
	
		this.timeoutActive=0;
		this.timeoutTime=0;
		
		this.timeoutActionDestinationQueueId=0;
		this.timeoutMessage='';
		this.noAgentActive=0;
		this.noAgentMessage='';
		this.noAgentActionDestinationQueueId=0;
		this._meta=[];

		
	}
}

/*
//tyntec

export const environment = {
	production: true,
	loginlogourl:"./assets/media//bg/bg-4-tyntec.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6-tyntec.png",
	facebooklink:"https://www.facebook.com/tyntec/",
	linkedinlink:"https://www.linkedin.com/company/tyntec/",
	whatsapplink:"https://wa.me/4989202451100?text=Hi",
	twitterlink:"https://twitter.com/tyntec",
	clientfullname:"Tyntec",
	clientBy:"WittyBots by SimpleTECH",
	clientweburl:"https://www.tyntec.com",
	clientcontacttourl:"mailto:sales@tyntec.com",
	clientabouturl:"https://www.tyntec.com/about-tyntec",
	clientByUrl:"https://www.wittybots.uy/",
	baseApiUrl:"https://multiagent.test2.wittybots.uy/api",
	version:"1.1.0",
	client:"tyntec",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/
//test
/*

export const environment = {
	socketChatUrl:"wss://multiagent.test.wittybots.uy:8082",
	loginlogourl:"./assets/media//bg/bg-4.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6.png",
	facebooklink:"https://www.facebook.com/wittybots/",
	linkedinlink:"https://www.linkedin.com/company/11449604",
	whatsapplink:"https://api.whatsapp.com/send?phone=59892462479",
	twitterlink:"#",
	clientfullname:"WittyBots",
	clientweburl:"https://www.wittybots.uy",
	clientcontacttourl:"mailto:soporte@simpletech.com.uy",
	clientabouturl:"https://www.wittybots.uy/acerca-de",
	clientBy:"WittyBots by SimpleTECH",
	clientByUrl:"https://www.wittybots.uy/",
	baseAdapterUrl:"https://client.test.wittybots.uy/production/",
	production: true,
	baseApiUrl:"https://multiagent.test.wittybots.uy/api",
	//baseApiUrl:"https://multiagent.wittybots.uy/api",
	version:"1.3.1.1",
	client:"witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey:'adas98jadad98ajdasjd',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/


//preprod
/*

export const environment = {
	socketChatUrl:"wss://socketagentpreprod.wittybots.uy",
	loginlogourl:"./assets/media//bg/bg-4.jpg",
	headerlogourl:"../../../assets/media/logos/logo-6.png",
	facebooklink:"https://www.facebook.com/wittybots/",
	linkedinlink:"https://www.linkedin.com/company/11449604",
	whatsapplink:"https://api.whatsapp.com/send?phone=59892462479",
	twitterlink:"#",
	clientfullname:"WittyBots",
	clientweburl:"https://www.wittybots.uy",
	clientcontacttourl:"mailto:soporte@simpletech.com.uy",
	clientabouturl:"https://www.wittybots.uy/acerca-de",
	clientBy:"WittyBots by SimpleTECH",
	clientByUrl:"https://www.wittybots.uy/",
	
	production: true,
	
	baseApiUrl:"https://multiagentpreprod.wittybots.uy/api",
	baseAdapterUrl:"https://client2preprod.wittybots.uy/production/",
	version:"1.4.0",
	client:"witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey:'adas98jadad98ajdasjd',
	 VAPID_PUBLIC_KEY : 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};
*/


//prod

export const environment = {
	socketChatUrl: "wss://socketagent.wittybots.uy",

	loginlogourl: "./assets/media//bg/bg-4.jpg",
	headerlogourl: "../../../assets/media/logos/logo-6.png",
	facebooklink: "https://www.facebook.com/wittybots/",
	linkedinlink: "https://www.linkedin.com/company/11449604",
	whatsapplink: "https://wa.me/59827140301",
	twitterlink: "#",
	clientfullname: "WittyBots",
	clientweburl: "https://www.wittybots.uy",
	clientcontacttourl: "mailto:soporte@simpletech.com.uy",
	clientabouturl: "https://www.wittybots.uy/acerca-de",
	clientBy: "WittyBots by SimpleTECH",
	clientByUrl: "https://www.wittybots.uy/",
	//baseAdapterUrl:"https://corealb.wittybots.uy/production/",
	production: true,
	baseApiUrl: "https://multiagent.wittybots.uy/api",
	version: "1.4.6",
	client: "witty",
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	targetServerKey: 'adas98jadad98ajdasjd',
	inviteWEBRTCUrl: 'https://webrtc.wittybots.uy:8888/client2/examples/demo/streaming/video-chat-and-screen-sharing/video-chat-and-screen-sharing.html',
	VAPID_PUBLIC_KEY: 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo'

};

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { EndPoint } from '../_models/enpoint.model';

const API_USERS_URL = environment.baseApiUrl+'/agent.php/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

export interface SipData {
	webrtcUrl:string,
	sipDomain: string,
    sipOutboundProxy: string,
    sipPort: string,
    sipAuthName:string,
    sipLogin:string,
    sipPassword:string,
  }
@Injectable()
export class AuthService {
    currentUser:User;
    sipData:SipData={webrtcUrl:"",
        sipDomain: "",
        sipOutboundProxy: "",
        sipPort: "",
        sipAuthName:"",
        sipLogin:"",
        sipPassword:""};
    
    canMakeCalls: BehaviorSubject<boolean> = new BehaviorSubject(false);
    callTo: BehaviorSubject<string> = new BehaviorSubject("");
    callWEBTo: BehaviorSubject<string> = new BehaviorSubject("");
    callWEBMessageTo: BehaviorSubject<string[]> = new BehaviorSubject([]);
    constructor(private http: HttpClient, private router: Router) {

        this.getUserByToken().subscribe(data=> {
            this.currentUser=data;
            
            this.setNotif();
        });
        

    }
    // Authentication/Authorization
getSipData()
{
    this.getSipDataFromBack().subscribe(data=>
        {
          //  this.sipData=data;
            //if(this.sipData==null)
            //{
                
                this.sipData.sipAuthName="A8Q5eZKuyE";//yGvWfdt1NV
                this.sipData.sipDomain="webrtc3cx.my3cx.us"
                this.sipData.sipOutboundProxy="webrtc3cx.my3cx.us"
                this.sipData.sipPort="5060";
                this.sipData.webrtcUrl="wss://webrtc.wittybots.uy:8443";
                this.sipData.sipLogin="101";
                this.sipData.sipPassword="sPBCO9Ao6E";
                this.currentUser.extension="101";
            //}
            //else
              //  this.sipData.sipLogin=this.currentUser.extension;
            console.log("fulling sipdata");

            //if(data!=null && data!=undefined && this.currentUser.extension!=null && this.currentUser.extension!="")
                this.canMakeCalls.next(true);
            
        })
    }   
setNewCall(callWith:string)
{
    this.callTo.next(callWith);
}
setNewCallWEB(callWith:string)
{
    this.callWEBTo.next(callWith);
}
setCallWEBMessageTo(message:string,to:string)
{
    let s:string[]=[];
    s.push(to);
    s.push(message);
    
    this.callWEBMessageTo.next(s);
}


    
    getSipDataFromBack(): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
    return this.http.get(API_USERS_URL + `/getsipdata`, { headers: httpHeaders })
                        .pipe(
                            map((response) => {
                                if (response['status']=="success")
                                {
                                        if(response!=null)
                                            return response['data'];
                                        else
                                            return null;
                                            
                                }
                                else
                                        return null;
                                })
                                
                            );
        }      
    setNotif(): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
    return this.http.get(API_USERS_URL + `/getfirepushqueueid`, { headers: httpHeaders })
                        .pipe(
                            map((response) => {
                                
                                this.currentUser.firePushQueueIds=response['data'];
                                    
                               
                                })
                                
                            );
        }      
        
    getTarget(): Observable<EndPoint> {

        
        const httpHeaders = new HttpHeaders(); 
        let id=this.currentUser.id;
    return this.http.get(API_USERS_URL + `/getTargetByAgentId/${id}`, { headers: httpHeaders })
                        .pipe(
                         
                            map((response) => {
                                let e:EndPoint;
                                e=response['data'];
                                sessionStorage.setItem(environment.targetServerKey,JSON.stringify(e));
                                return e;
                                    
                               
                                })
                                
                            );
        }      
    login(username: string, password: string): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
        
        return this.http.post (API_USERS_URL+"/login", { username, password },{ headers: httpHeaders })
        .pipe(
            map((response) => {
                if (response['status']=="success")
                {
                    var us : User;
                    
                    us=response['data'];
                    if(us.active)
                    {
                         this.currentUser=us;
                        
                     
                         this.getTarget();
                    
                            
                        return us;
                    }
                    else 
                    {
                        sessionStorage.setItem("lastErrorFromServer","Usuario inactivo");
                        return null;
                    }
                }
                else
                { 
                  //  console.log(response["data"]);
                       sessionStorage.setItem("lastErrorFromServer",response["data"]);
            

                    return null;

                }
            })
            
        );
        
    }
    reloadCurrentUser()
    {
        this.getUserByToken().subscribe(data=> {
            this.currentUser=data;
            this.setNotif();
        });
    }
    unsetNot(queueId:string): Observable<any> 
    {
        		
							
        const httpHeaders = new HttpHeaders(); 
        
        
        return this.http.post(API_USERS_URL+"/unsetfirepushqueueid", { queueId},{ headers: httpHeaders })
        .pipe(
            map((response) => {
                if (response['status']=="success")
                 return true;
                else
                {
                    return null;
                }
            })
            
        );
        
    }
    setNot(queueId:string): Observable<any> 
    {
        		
							
        const httpHeaders = new HttpHeaders(); 
        
        
        return this.http.post(API_USERS_URL+"/setfirepushqueueid", { queueId},{ headers: httpHeaders })
        .pipe(
            map((response) => {
                if (response['status']=="success")
                 return true;
                else
                {
                    return null;
                }
            })
            
        );
        
    }
    setiamOnLine(agentuserid:string,iamonline:boolean): Observable<any> {
        
					
							
        const httpHeaders = new HttpHeaders(); 
        
        
        return this.http.post(API_USERS_URL+"/setiamonline", { agentuserid,iamonline},{ headers: httpHeaders })
        .pipe(
            map((response) => {
                if (response['status']=="success")
                 return true;
                else
                {
                    /*
                    sessionStorage.removeItem('userId');
							sessionStorage.removeItem('username');
							sessionStorage.removeItem('userRol');
							sessionStorage.removeItem('userViews');
							sessionStorage.removeItem('userDisplayName');
		                    sessionStorage.removeItem(environment.authTokenKey);
                            console.log("lastErrorFromServer :"+response["data"]);

            	console.log("Token expired or error");
				this.router.navigateByUrl('/auth/login');
			*/
                    return null;
                }
            })
            
        );
        
    }
    checkOnline(): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
        
		return this.http.get(API_USERS_URL + `/getlanguage/1`, { headers: httpHeaders })
		.pipe(
            
			map((response) => {
			
				return true;
            }),
            catchError(err => {
                return of('Server error');
            
            })
           
			
		);
       
	}
    
    getLanguage(id: number): Observable<string> {
        const httpHeaders = new HttpHeaders(); 
       
		return this.http.get(API_USERS_URL + `/getlanguage/${id}`, { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				var res : string;
				
				res=response['data'];
				if(res)
				{
					
					return res;
					
				}
				else
				{
				  return null;
				}
            })
           
			
		);
       
	}


    setLanguage(agentuserid:number,language:string): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
        return this.http.post(API_USERS_URL+"/setlanguage", { agentuserid,language},{ headers: httpHeaders })
        .pipe(
            map((response) => {
                if (response['status']=="success")
                 return true;
                else
                {
                  
                    return null;
                }
            })
            
        );
        
    }

    getUserByToken(): Observable<User> {
        const userToken = sessionStorage.getItem(environment.authTokenKey);
        if(userToken!=null && userToken!="")
        {
        const httpHeaders = new HttpHeaders(); 
        
        return this.http.get<User>(API_USERS_URL+"/byToken", { headers: httpHeaders })
        .pipe(
            map((response) => {
                
                var us : User;
                
                us=response['data'];
                if(us.active)
                {
                       return us;
                }
                else
                {
                    this.router.navigateByUrl('/auth/login');
                    console.log("Token expired");
                    return null;
                }
            })
           
            
        );
        }
        else
        return new Observable<User>();
    }

     canRender(title:String):boolean {


         if( title=="Configuration" || title=="Configuración")
         return false;
                    let rol:string;
                let u:User=this.currentUser;
                if(u==null || u ==undefined)
                {
                       rol =sessionStorage.getItem("userRol");
                       if(rol==null || rol=="")
                            return false;
                }
                else
                {
                    rol=u.rol;
                }
                 
               
                if(rol=="Agente" && (title=="Administrar" || title=="Indicadores" || title=="Indicators" || title=="Configuration" || title=="Configuración" || title=="Bandejas" || title=="Queues" || title=="Users" || title=="Usuarios"))
                return false
        
                return true;
         
        
    }
    
    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
        
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
    	return this.http.get(API_USERS_URL + '/forgot?=' + email)
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }


    getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        const httpHeaders = new HttpHeaders(); 
        
		return this.http.get(API_USERS_URL + `/get/${userId}`, { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				var res : User;
				
				res=response['data'];
				if(res)
				{
					
					return res;
					
				}
				else
				{
				  return null;
				}
            })
           
			
		);
       
	}


    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
      	      return this.http.put(API_USERS_URL, _user);
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
    	
		   return this.http.post<User>(API_USERS_URL, user);
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders(); 
        
		      return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams);
    }

    
    getAllAgents(): Observable<User[]> {
		
		const httpHeaders = new HttpHeaders(); 
        
		return this.http.get(API_USERS_URL+"/getAllAgentsByBotId", { headers: httpHeaders })
		.pipe(
			map((response) => {
				
				var queues : User[];
				
				queues=response['data'];
				if(queues)
				{
					
					return queues;
					
				}
				else
				{
				  return null;
				}
            })
            
			
		);
	
	}

    // Permission
    /*
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders(); 
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders(); 
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders(); 
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
	}
*/
 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}

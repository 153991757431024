import { BaseModel } from '../../_base/crud';
import { Cant_Date } from './cant_date.model';

export class QueueStat  extends BaseModel {
	id:number;
	name:string;
	data:Cant_Date[];

	clear() {

		this.id=undefined;
		this.name="";
		this.data=[];
	
		
	
	}
	
		
}

// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { QueueModel } from '../_models/queue.model';
import { Update } from '@ngrx/entity';

export enum QueueActionTypes {
    QueueOnServerCreated = '[Edit Queue Component] Queue On Server Created',
    QueueCreated = '[Edit Queue Component] Queue Created',
    QueueUpdated = '[Edit Queue Component] Queue Updated',
    QueuesStatusUpdated = '[Queues List Page] Queues Status Updated',
    OneQueueDeleted = '[Queues List Page] One Queue Deleted',
    ManyQueuesDeleted = '[Queues List Page] Many Selected Queues Deleted',
    QueuesPageRequested = '[Queues List Page] Queues Page Requested',
    QueuesPageLoaded = '[Queues API] Queues Page Loaded',
    QueuesPageCancelled = '[Queues API] Queues Page Cancelled',
    QueuesPageToggleLoading = '[Queues] Queues Page Toggle Loading',
    QueuesActionToggleLoading = '[Queues] Queues Action Toggle Loading'
}

export class QueueOnServerCreated implements Action {
    readonly type = QueueActionTypes.QueueOnServerCreated;
    constructor(public payload: { Queue: QueueModel }) { }
}

export class QueueCreated implements Action {
    readonly type = QueueActionTypes.QueueCreated;
    constructor(public payload: { Queue: QueueModel }) { }
}

export class QueueUpdated implements Action {
    readonly type = QueueActionTypes.QueueUpdated;
    constructor(public payload: {
        partialQueue: Update<QueueModel>, // For State update
        Queue: QueueModel // For Server update (through service)
    }) { }
}

export class QueuesStatusUpdated implements Action {
    readonly type = QueueActionTypes.QueuesStatusUpdated;
    constructor(public payload: {
        queues: QueueModel[],
        status: number
    }) { }
}

export class OneQueueDeleted implements Action {
    readonly type = QueueActionTypes.OneQueueDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyQueuesDeleted implements Action {
    readonly type = QueueActionTypes.ManyQueuesDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class QueuesPageRequested implements Action {
    readonly type = QueueActionTypes.QueuesPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class QueuesPageLoaded implements Action {
    readonly type = QueueActionTypes.QueuesPageLoaded;
    constructor(public payload: { queues: QueueModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class QueuesPageCancelled implements Action {
    readonly type = QueueActionTypes.QueuesPageCancelled;
}

export class QueuesPageToggleLoading implements Action {
    readonly type = QueueActionTypes.QueuesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class QueuesActionToggleLoading implements Action {
    readonly type = QueueActionTypes.QueuesActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type QueueActions = QueueOnServerCreated
| QueueCreated
| QueueUpdated
| QueuesStatusUpdated
| OneQueueDeleted
| ManyQueuesDeleted
| QueuesPageRequested
| QueuesPageLoaded
| QueuesPageCancelled
| QueuesPageToggleLoading
| QueuesActionToggleLoading;

import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { QueuesService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    QueueActionTypes,
    QueuesPageRequested,
    QueuesPageLoaded,
    ManyQueuesDeleted,
    OneQueueDeleted,
    QueuesPageToggleLoading,
    QueuesStatusUpdated,
    QueueUpdated,
    QueueCreated,
    QueueOnServerCreated
} from '../_actions/queue.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class QueueEffects {
    showPageLoadingDistpatcher = new QueuesPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new QueuesPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new QueuesPageToggleLoading({ isLoading: false });

    @Effect()
    loadQueuesPage$ = this.actions$
        .pipe(
            ofType<QueuesPageRequested>(QueueActionTypes.QueuesPageRequested),
            mergeMap(( { payload } ) => {
                
                this.store.dispatch(this.showPageLoadingDistpatcher);
                //call to service
                const requestToServer = this.queuesService.findQueues(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new QueuesPageLoaded({
                    queues: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteQueue$ = this.actions$
        .pipe(
            ofType<OneQueueDeleted>(QueueActionTypes.OneQueueDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return null;//this.queuesService.deleteQueue(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteQueues$ = this.actions$
        .pipe(
            ofType<ManyQueuesDeleted>(QueueActionTypes.ManyQueuesDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.queuesService.deleteQueues(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateQueuesStatus$ = this.actions$
        .pipe(
            ofType<QueuesStatusUpdated>(QueueActionTypes.QueuesStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.queuesService.updateStatusForQueue(payload.queues, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateQueue$ = this.actions$
        .pipe(
            ofType<QueueUpdated>(QueueActionTypes.QueueUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.queuesService.updateQueue(payload.Queue);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createQueue$ = this.actions$
        .pipe(
            ofType<QueueOnServerCreated>(QueueActionTypes.QueueOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.queuesService.createQueue(payload.Queue).pipe(
                    tap(res => {
                        this.store.dispatch(new QueueCreated({ Queue: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new QueuesPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private queuesService: QueuesService, private store: Store<AppState>) { }
}

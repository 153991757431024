// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { QueuesState } from '../_reducers/queue.reducers';
import { QueueModel } from '../_models/queue.model';

export const selectQueuesState = createFeatureSelector<QueuesState>('queues');

export const selectQueueById = (queueId: number) => createSelector(
    selectQueuesState,
    queuesState => queuesState.entities[queueId]
);

export const selectQueuesPageLoading = createSelector(
    selectQueuesState,
    queuesState => queuesState.listLoading
);

export const selectQueuesActionLoading = createSelector(
    selectQueuesState,
    customersState => customersState.actionsloading
);

export const selectQueuesPageLastQuery = createSelector(
    selectQueuesState,
    queuesState => queuesState.lastQuery
);

export const selectLastCreatedQueueId = createSelector(
    selectQueuesState,
    queuesState => queuesState.lastCreatedQueueId
);

export const selectQueuesInitWaitingMessage = createSelector(
    selectQueuesState,
    queuesState => queuesState.showInitWaitingMessage
);

export const selectQueuesInStore = createSelector(
    selectQueuesState,
    queuesState => {
        const items: QueueModel[] = [];
        each(queuesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        
        
        const result: QueueModel[] = httpExtension.sortArray(items, queuesState.lastQuery.sortField, queuesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, queuesState.totalCount, '');
    }
);

export const selectHasQueuesInStore = createSelector(
    selectQueuesInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
